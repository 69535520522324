import { Alert, Button, Container, Group, Text, Title } from "@mantine/core";
import React from "react";
import { useParams } from "react-router-dom";
import {
  IncomeFormValues,
  useIncomeFormContext,
} from "../context/form-context";
import { parseSlug } from "../utils";
import InstructionsContent from "../components/InstructionsContent/InstructionsContent";
import IncomeTaxCalculatorForm from "../components/IncomeTaxCalculatorForm/IncomeTaxCalculatorForm";
import { IconInfoCircle } from "@tabler/icons-react";

const IncomeTaxCalculator: React.FC = () => {
  const { slug } = useParams();
  const form = useIncomeFormContext();
  const [closeDisclaimer, setCloseDisclaimer] = React.useState<boolean>(false);
  const onCloseDisclaimer = () => setCloseDisclaimer(true);

  React.useEffect(() => {
    const parts = slug?.split("-vs-");
    let initialValues: IncomeFormValues;
    if (parts?.length === 2) {
      initialValues = {
        salary1: parseSlug(parts[0]),
        salary2: parseSlug(parts[1]),
        currentPath: "salary1",
      };
      form.setValues(initialValues);
    } else if (slug) {
      initialValues = {
        salary1: parseSlug(slug),
        salary2: {
          grossIncome: undefined,
          pensionContributions: 0,
          employerPensionContributions: 0,
          studentLoanPlans: [],
          isBlind: false,
          giftAid: 0,
          taxCode: undefined,
          taxYear: "24-25",
        },
        currentPath: "salary1",
      };
      form.setValues(initialValues);
    }
  }, [slug]);

  const quickButtons = [
    // {
    //   title: "£1k/mo",
    //   slug: "1200000A-P5S-EP3-SL5-Y24-25"
    // },
    {
      title: "£2k/mo",
      slug: "2790000A-P5S-EP3-SL5-Y24-25",
    },
    {
      title: "£3k/mo",
      slug: "4424873A-P5S-EP3-SL5-Y24-25",
    },
    {
      title: "£4k/mo",
      slug: "6229734A-P5S-EP3-SL5-Y24-25",
    },
    {
      title: "£5k/mo",
      slug: "8226400A-P5S-EP3-SL5-Y24-25",
    },
    {
      title: "£6k/mo",
      slug: "10223083A-P5S-EP3-SL5-Y24-25",
    },
    // {
    //   title: "£7k/mo",
    //   slug: "13002083A-P5S-EP3-SL5-Y24-25"
    // }
  ];

  const goToSalary = (buttonSlug: string) => {
    if (
      form.getValues().salary1.grossIncome &&
      !form.getValues().salary2.grossIncome
    ) {
      window.location.href = `/calculator/${slug}-vs-${buttonSlug}`;
    } else {
      window.location.href = `/calculator/${buttonSlug}`;
    }
  };

  return (
    <>
      <Title mt="md" mb="xl" order={1}>
        Calculate your income tax, pension deductions and take home pay
      </Title>
      <Group mt="md">
        <Text>I want an after tax pay of...</Text>
        {quickButtons.map((btn) => (
          <Button
            color="pink"
            data-href={`/calculator/${btn.slug}`}
            onClick={() => goToSalary(btn.slug)}
          >
            {btn.title}
          </Button>
        ))}
      </Group>
      <IncomeTaxCalculatorForm incomeForm={form} />
      <Title mt="xl" mb="lg" order={2}>
        How to use this tool
      </Title>
      <InstructionsContent />
      <Group mt="xl" mb="xl">
        {!closeDisclaimer && (
          <Container size="sm">
            <Alert
              withCloseButton
              variant="light"
              color="red"
              title="Disclaimer"
              icon={<IconInfoCircle />}
              onClose={onCloseDisclaimer}
            >
              The information provided by this tool is for illustrative purposes
              only. Your actual payslip may vary depending on multiple factors
              not accounted for by this tool. Always seek professional advice
              from a qualified financial advisor before making major financial
              decisions.
            </Alert>
          </Container>
        )}
      </Group>
    </>
  );
};

export default IncomeTaxCalculator;
