import {
  Button,
  Center,
  Group,
  Input,
  List,
  ListItem,
  Text,
  Title,
} from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import {
  useDisclosure,
  useDocumentTitle,
  useScrollIntoView,
} from "@mantine/hooks";
import { IconRefresh } from "@tabler/icons-react";
import { IncomeDetails, IncomeTaxBreakdown } from "backend/src/types";
import React, { FormEvent } from "react";
import {
  IncomeFormValues,
  incomeFormActions,
  useIncomeFormContext,
} from "../../context/form-context";
import {
  defaultDocumentTitle,
  gbpFormatter,
  getMetaTitle,
  getSlug,
  getTaxBandName,
  getTitle,
  percFormatter,
} from "../../utils";
import ConfigureSalaryModal from "../ConfigureSalaryModal/ConfigureSalaryModal";
import { IncomeTaxBandsBreakdown } from "../IncomeTaxBandsBreakdown/IncomeTaxBandsBreakdown";
import { InputWithButton } from "../InputWithButton/InputWithButton";
import { NITaxBandsBreakdown } from "../NITaxBandsBreakdown/NITaxBandsBreakdown";
import ShareDeepLinkButton from "../ShareDeepLinkButton/ShareDeepLinkButton";
import { StatsGrid } from "../StatsGrid/StatsGrid";
import { TableSalaries } from "../TableSalaries/TableSalaries";
import { IncomeDetailsSEOText } from "../IncomeDetailsSEOText/IncomeDetailsSEOText";
import { StudentLoanRepaymentsBreakdown } from "../StudentLoanRepaymentsBreakdown/StudentLoanRepaymentsBreakdown";

type IncomeTaxCalculatorFormProps = {
  incomeForm: UseFormReturnType<
    IncomeFormValues,
    (values: IncomeFormValues) => IncomeFormValues
  >;
};

const IncomeTaxCalculatorForm: React.FC<IncomeTaxCalculatorFormProps> = ({
  incomeForm,
}) => {
  const [data, setData] = React.useState<IncomeTaxBreakdown[]>();
  const [documentTitle, setDocumentTitle] =
    React.useState<string>(defaultDocumentTitle);
  useDocumentTitle(documentTitle);

  const [opened, { open, close }] = useDisclosure(false);

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 60,
  });

  const openSalary1Modal = () => {
    incomeFormActions.setFieldValue("currentPath", "salary1");
    open();
  };

  const openSalary2Modal = () => {
    incomeFormActions.setFieldValue("currentPath", "salary2");
    open();
  };

  const onCloseSalaryModel = () => {
    close();
    scrollIntoView({
      alignment: "center",
    });
  };

  const form = useIncomeFormContext();

  const handleIncomeFormSubmit = incomeForm.onSubmit((values) => {
    // console.info("<><><> submit values", values);
  });

  const handleIncomeFormReset = (event: FormEvent<HTMLFormElement>) => {
    incomeForm.onReset(event);
    /* @ts-ignore-next-line */
    incomeForm.setFieldValue("salary1.grossIncome", "");
    setData(undefined);
  };

  React.useEffect(() => {
    let salaries: Partial<IncomeDetails>[] = [];
    const values = form.getTransformedValues();
    if (form.values.salary1.grossIncome) {
      salaries.push({
        grossIncome: +form.values.salary1.grossIncome,
        pensionType: values.salary1.pensionType,
        pensionContributionsType: values.salary1.pensionContributionsType,
        pensionContributions: values.salary1.pensionContributions,
        employerPensionContributions:
          values.salary1.employerPensionContributions,
        studentLoanPlans: values.salary1.studentLoanPlans,
        giftAid: values.salary1.giftAid,
        isBlind: values.salary1.isBlind,
        niTaxCategory: values.salary1.niTaxCategory,
        taxYear: values.salary1.taxYear,
        ...(values.salary1.taxCode ? { taxCode: values.salary1.taxCode } : {}),
      });
      if (form.values.salary2.grossIncome) {
        salaries.push({
          grossIncome: +form.values.salary2.grossIncome,
          pensionType: values.salary2.pensionType,
          pensionContributionsType: values.salary2.pensionContributionsType,
          pensionContributions: values.salary2.pensionContributions,
          employerPensionContributions:
            values.salary2.employerPensionContributions,
          studentLoanPlans: values.salary2.studentLoanPlans,
          giftAid: values.salary2.giftAid,
          isBlind: values.salary2.isBlind,
          niTaxCategory: values.salary2.niTaxCategory,
          taxYear: values.salary2.taxYear,
          ...(values.salary2.taxCode
            ? { taxCode: values.salary2.taxCode }
            : {}),
        });
      }
      fetch(
        `${process.env.REACT_APP_INCOME_TAX_CALCULATOR_API_URL}/compare-salaries`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            salaries,
          }),
        }
      )
        .then((res) => res.json())
        .then((data) => setData(data));
    }
  }, [form.values.salary1, form.values.salary2]);

  const renderSlug = React.useMemo(() => {
    if (!data?.length) {
      return null;
    }
    let deepLink = `${
      process.env.REACT_APP_INCOME_TAX_CALCULATOR_HOST_URL
    }/calculator/${getSlug(data[0].inputs)}`;
    let shareWord = "salary";
    if (data?.length === 2) {
      deepLink += `-vs-${getSlug(data[1].inputs)}`;
      shareWord = "comparison";
    }
    return (
      <Center>
        <Group mt="xl" mb="xl">
          <Text fz="sm">Share this {shareWord}</Text>
          <Input readOnly type="text" value={deepLink} />
          <ShareDeepLinkButton url={deepLink} />
        </Group>
      </Center>
    );
  }, [data]);

  const renderHeadlineSEO = React.useMemo(() => {
    const getHeadlineText = (
      grossIncome: number,
      net: number,
      netMonthly: number
    ) =>
      `${gbpFormatter(grossIncome / 100)} After Tax is ${gbpFormatter(
        net / 100
      )} Net (${gbpFormatter(netMonthly / 100)}/mo.)`;
    if (typeof data !== "undefined") {
      const headline = getHeadlineText(
        data[0].inputs.grossIncome,
        data[0].result.net,
        data[0].result.netMonthly
      );
      if (data.length === 2) {
        const subheadline = getHeadlineText(
          data[1].inputs.grossIncome,
          data[1].result.net,
          data[1].result.netMonthly
        );
        return (
          <Group mt="xl" mb="xl">
            <Title order={2}>{headline}</Title>
            <Title order={3}>{subheadline}</Title>
          </Group>
        );
      }
      return (
        <Title order={2} mt="xl" mb="xl">
          {headline}
        </Title>
      );
    }
  }, [data]);

  const renderResultSEO = React.useMemo(() => {
    if (typeof data !== "undefined") {
      const salaryTitle = getMetaTitle(data);
      setDocumentTitle(salaryTitle);
      return (
        <>
          <Title order={2} mt="xl">
            {salaryTitle}
          </Title>
          <IncomeDetailsSEOText
            title={data.length === 2 ? "Salary 1" : undefined}
            incomeTaxBreakdown={data[0]}
          />
          {data.length === 2 && (
            <IncomeDetailsSEOText
              title="Salary 2"
              incomeTaxBreakdown={data[1]}
            />
          )}
        </>
      );
    }
  }, [data]);

  const renderIncomeTaxBreakdownSEO = React.useMemo(() => {
    if (data?.length === 1) {
      const incomeTax = gbpFormatter(data[0].result.incomeTax / 100.0);
      const incomeTaxBands = data[0].result.incomeTaxBands.map(
        (taxBand, idx) =>
          `${percFormatter(taxBand.rate)} on ${getTaxBandName(
            taxBand
          )} = ${gbpFormatter(data[0].result.incomeTaxBreakdown[idx] / 100.0)}`
      );
      return (
        <>
          <Text mt="md">
            For {getTitle(data[0].inputs)} the income tax breakdown is as
            follows:
          </Text>
          <List type="unordered">
            <ListItem>
              <Text>Income Tax: {incomeTax}</Text>
              <List type="unordered">
                {incomeTaxBands.map((taxBand) => (
                  <ListItem>{taxBand}</ListItem>
                ))}
              </List>
            </ListItem>
          </List>
        </>
      );
    }
  }, [data]);

  const renderNITaxBreakdownSEO = React.useMemo(() => {
    if (data?.length === 1) {
      const nationalInsuranceTax = gbpFormatter(
        data[0].result.nationalInsuranceTax / 100.0
      );
      const nationalInsuranceTaxBands =
        data[0].result.nationalInsuranceTaxBands.map(
          (taxBand, idx) =>
            `${percFormatter(taxBand.rate)} on ${getTaxBandName(
              taxBand
            )} = ${gbpFormatter(
              data[0].result.nationalInsuranceTaxBreakdown[idx] / 100.0
            )}`
        );
      return (
        <>
          <Text mt="md">
            For {getTitle(data[0].inputs)} the national insurance tax breakdown
            is as follows:
          </Text>
          <List type="unordered">
            <ListItem>
              <Text>National Insurance Tax: {nationalInsuranceTax}</Text>
              <List type="unordered">
                {nationalInsuranceTaxBands.map((taxBand) => (
                  <ListItem>{taxBand}</ListItem>
                ))}
              </List>
            </ListItem>
          </List>
        </>
      );
    }
  }, [data]);

  const renderIncomeTaxBreakdown = React.useMemo(() => {
    if (typeof data !== "undefined") {
      return (
        <>
          <Title order={3} mt="md">
            Income Tax Breakdown
          </Title>
          <IncomeTaxBandsBreakdown data={data} />
        </>
      );
    }
  }, [data]);

  const renderNITaxBreakdown = React.useMemo(() => {
    if (typeof data !== "undefined") {
      return (
        <>
          <Title order={3} mt="md">
            National Insurance Tax Breakdown
          </Title>
          <NITaxBandsBreakdown data={data} />
        </>
      );
    }
  }, [data]);

  const renderStudentLoanRepaymentsBreakdown = React.useMemo(() => {
    if (
      typeof data !== "undefined" &&
      data.some(
        (incomeTaxBreakdown) =>
          incomeTaxBreakdown.result.studentLoanDeductions.length > 0
      )
    ) {
      return (
        <>
          <Title order={3} mt="md">
            Student Loan Repayments Breakdown
          </Title>
          <StudentLoanRepaymentsBreakdown data={data} />
        </>
      );
    }
  }, [data]);

  const renderStats = React.useMemo(() => {
    if (typeof data !== "undefined" && data.length && data.length > 1) {
      return (
        <>
          <Title order={3} mt="xl">
            Salary Comparison
          </Title>
          <StatsGrid data={data} />
        </>
      );
    }
    return null;
  }, [data]);

  const hasData = typeof data !== "undefined" && data.length > 0;

  const renderSalaryBreakdown = React.useMemo(() => {
    if (hasData) {
      return <TableSalaries data={data} />;
    }
    return null;
  }, [hasData, data]);

  const renderResetButton = React.useMemo(() => {
    if (hasData) {
      return (
        <Center>
          <Button
            type="reset"
            radius="xl"
            variant="outline"
            leftSection={<IconRefresh />}
          >
            Start over
          </Button>
        </Center>
      );
    }
    return null;
  }, [hasData]);

  return (
    <form onSubmit={handleIncomeFormSubmit} onReset={handleIncomeFormReset}>
      <Group w="100%" justify="center" mb={!hasData ? 200 : "lg"}>
        <Group mt="xl" hiddenFrom="sm">
          <InputWithButton
            pathPrefix="salary1"
            value={form.values.salary1.grossIncome}
            onChange={(value) => {
              form.setFieldValue("salary1.grossIncome", +value);
            }}
            label={hasData && "Salary 1"}
            onRightSectionClick={openSalary1Modal}
            w="95%"
          />
          {hasData && (
            <InputWithButton
              pathPrefix="salary2"
              value={form.values.salary2.grossIncome}
              onChange={(value) => {
                form.setFieldValue("salary2.grossIncome", +value);
              }}
              label="Salary 2"
              onRightSectionClick={openSalary2Modal}
              w="95%"
            />
          )}
        </Group>
        <Group mt="xl" grow visibleFrom="sm" w={!hasData ? "50%" : "90%"}>
          <InputWithButton
            pathPrefix="salary1"
            value={form.values.salary1.grossIncome}
            onChange={(value) => {
              form.setFieldValue("salary1.grossIncome", +value);
            }}
            label={hasData && "Salary 1"}
            onRightSectionClick={openSalary1Modal}
          />
          {hasData && (
            <InputWithButton
              pathPrefix="salary2"
              value={form.values.salary2.grossIncome}
              onChange={(value) => {
                form.setFieldValue("salary2.grossIncome", +value);
              }}
              label="Salary 2"
              onRightSectionClick={openSalary2Modal}
            />
          )}
        </Group>
      </Group>
      <div ref={targetRef}>{renderHeadlineSEO}</div>
      {renderSalaryBreakdown}
      {renderSlug}
      {renderResetButton}
      {renderResultSEO}
      {renderStats}
      {renderIncomeTaxBreakdown}
      {renderIncomeTaxBreakdownSEO}
      {renderNITaxBreakdown}
      {renderNITaxBreakdownSEO}
      {renderStudentLoanRepaymentsBreakdown}
      {renderSlug}
      {renderResetButton}
      <ConfigureSalaryModal opened={opened} onClose={onCloseSalaryModel} />
    </form>
  );
};

export default IncomeTaxCalculatorForm;
