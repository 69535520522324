import {
  Button,
  Group,
  List,
  Text,
  ThemeIcon,
  Title,
  rem,
} from "@mantine/core";
import { IconCircleCheck } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";

type WelcomeContentProps = {
  exampleTable: React.ReactNode;
  calculatorLink: string;
};

const WelcomeContent: React.FC<WelcomeContentProps> = ({
  exampleTable,
  calculatorLink,
}) => {
  const navigate = useNavigate();
  return (
    <Group w="100%" justify="center">
      <Group mt="md" mb="xl">
        <Title order={1}>Welcome to the best income tax calculator</Title>
        <Text mt="md">
          Easily navigate the intracacies of the U.K. tax system with our
          intuitive income tax calculator. Whether you're a seasoned
          professional or just starting your career, our tool provides precise
          calculations to help you plan your finances more effectively.
        </Text>
        <Group w="100%">
          <Button
            variant="gradient"
            miw={270}
            radius="xl"
            onClick={() => navigate(calculatorLink)}
          >
            Get started
          </Button>
        </Group>
      </Group>
      <Group mt="md" mb="xl">
        <Title order={2}>Features</Title>
        <Group visibleFrom="sm">{exampleTable}</Group>
        <List
          spacing="md"
          center
          icon={
            <ThemeIcon color="teal" size={24} radius="xl">
              <IconCircleCheck style={{ width: rem(16), height: rem(16) }} />
            </ThemeIcon>
          }
        >
          <List.Item>
            <Title mt="md" order={3}>
              Efficient Tax Planning for U.K. Taxpayers
            </Title>
            <Text mt="md">
              Enter your salary, allowances, and deductions, and witness
              real-time calculations that illuminate the impact of your tax
              liability.
            </Text>
          </List.Item>
          <List.Item>
            <Title mt="md" order={3}>
              Comprehensive Results for Informed Financial Decisions
            </Title>
            <Text mt="md">
              Receive a detailed breakdown of your tax, National Insurance
              contributions, and take-home pay. Our tool not only calculates
              your current status but also offers insights for tax planning,
              providing personalised suggestions to optimise your financial
              position.
            </Text>
          </List.Item>
          <List.Item>
            <Title mt="md" order={3}>
              Mobile-friendly View
            </Title>
            <Text mt="md">
              Access our mobile-friendly tool on the go, making financial
              planning convenient and accessible whenever you need it. We
              understand the importance of staying connected and informed in
              today's fast-paced world.
            </Text>
          </List.Item>
          <List.Item>
            <Title mt="md" order={3}>
              Compare Two Salaries
            </Title>
            <Text mt="md">
              Enter a second salary to compare it with the first one by year,
              month, and week. To view day and hour rates please only enter 1
              salary. You can reset the view with the 'Start Over' button.
            </Text>
          </List.Item>
          <List.Item>
            <Title mt="md" order={3}>
              View your tax breakdown by tax bands
            </Title>
            <Text mt="md">
              You can now see how much tax you will pay across each tax band for
              both national insurance tax contributions and income tax.
            </Text>
          </List.Item>
        </List>
      </Group>
    </Group>
  );
};

export default WelcomeContent;
