import { Anchor, Group, Table, TableCaption, Text, Title } from "@mantine/core";
import { useDocumentTitle } from "@mantine/hooks";
import React from "react";

const UKTaxFAQ: React.FC = () => {
  useDocumentTitle("U.K. Tax FAQ - Income-Tax-Calculator.com");

  return (
    <Group w="100%">
      <Title order={1}>U.K. Tax FAQ</Title>
      <Title order={2} mt="xl">
        How much will I repay in Student Loan repayments?
      </Title>
      <Text mt="md">
        Your repayments are based on your income, not your outstanding debt. The
        plan you are on determines your repayment threshold (the minimum you
        need to earn before repayments are deducted from your PAYE payslips).
        The repayments are currently 9% of what you earn above the repayment
        threshold for undegraduate loans, and 6% of what you earn above the
        repayment threshold for postgraduate loans. You can{" "}
        <Anchor href="/calculator">
          use our FREE tool to estimate your repayments
        </Anchor>
        . Our tool also supports multiple loan types simultaneously. Check how
        much you&apos;d repay as a new graduate in 2024 with an annual salary of{" "}
        <Anchor href="/calculator/3500000A-Y24-25-vs-3500000A-SL5n-Y24-25">
          £35k with both Plan 5 (UG) and Plan 3 (PGL)
        </Anchor>{" "}
        loans.
      </Text>
      <Title order={2} mt="xl">
        Which student loan plan am I on?
      </Title>
      <Text mt="md">
        To determine which Student Finance England (SFE) loan plan you have
        based on the years the loan was active, you need to know the different
        loan plans and their respective timeframes. These include Plan 1, Plan
        2, Plan 3 (Postgraduate Loan), Plan 4 (Undegraduate Loan for Scottish
        students), and Plan 5. Each plan is associated with specific periods
        during which the loans were taken out. By logging into your Student
        Finance England account or reviewing your loan documents, you can find
        details about your loan plan. The table below outlines the active years
        of each loan type to help you identify which one you might be on.{" "}
        <Anchor href="/calculator/3500000A-SL5-Y24-25-vs-3500000A-SL2-Y24-25">
          See how much you would take home on a £35k salary on Plan 5 vs Plan 2
          student loans.
        </Anchor>
      </Text>
      <Table.ScrollContainer minWidth={480}>
        <Table>
          <TableCaption>
            This table helps you to identify what student loan plan you are on
            based on your residence, the year you started university, and your
            course type (undergraduate or postgraduate).
          </TableCaption>
          <Table.Thead>
            <Table.Tr>
              <Table.Th w="20%">Start Date</Table.Th>
              <Table.Th w="20%">England</Table.Th>
              <Table.Th w="20%">Wales</Table.Th>
              <Table.Th w="20%">Scotland</Table.Th>
              <Table.Th w="20%">Northern Ireland</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Th>Before 1 September 1998</Table.Th>
              <Table.Td colSpan={4}>You have a traditional loan</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th>On or after 1 September 1998</Table.Th>
              <Table.Td>Plan 1</Table.Td>
              <Table.Td>Plan 1</Table.Td>
              <Table.Td>Plan 4</Table.Td>
              <Table.Td>Plan 1</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th>On or after 1 September 2012</Table.Th>
              <Table.Td>Plan 2</Table.Td>
              <Table.Td>Plan 2</Table.Td>
              <Table.Td>Plan 4</Table.Td>
              <Table.Td>Plan 1</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th>On or after 1 August 2016</Table.Th>
              <Table.Td>Plan 2 (UG) or Plan 3 (PGL)</Table.Td>
              <Table.Td>Plan 2 (UG) or Plan 3 (PGL)</Table.Td>
              <Table.Td>Plan 4</Table.Td>
              <Table.Td>Plan 1</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th>On or after 1 September 2021</Table.Th>
              <Table.Td>Plan 2 (UG) or Plan 3 (PGL)</Table.Td>
              <Table.Td>Plan 2 (UG) or Plan 3 (PGL)</Table.Td>
              <Table.Td>Plan 4 (UG) or Plan 3 (PGL)</Table.Td>
              <Table.Td>Plan 1 (UG) or Plan 3 (PGL)</Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Th>On or after 1 September 2023</Table.Th>
              <Table.Td>Plan 5 (UG) or Plan 3 (PGL)</Table.Td>
              <Table.Td>Plan 5 (UG) or Plan 3 (PGL)</Table.Td>
              <Table.Td>Plan 4 (UG) or Plan 3 (PGL)</Table.Td>
              <Table.Td>Plan 1 (UG) or Plan 3 (PGL)</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
      <Title order={2} mt="xl">
        Where can I find my UTR number?
      </Title>
      <Text mt="md">
        To find your Unique Taxpayer Reference (UTR) number, you can refer to
        several official documents and sources. Your UTR number is a ten-digit
        identifier issued by HM Revenue and Customs (HMRC). It is prominently
        displayed on your previous tax returns, your 'Welcome to Self
        Assessment' letter, and any other correspondence from HMRC.
        Additionally, if you have registered for HMRC&apos;s online services,
        you can locate your UTR number within your personal tax account. It is
        advisable to keep this number secure and accessible for all tax-related
        matters.
      </Text>
      <Text mt="md">
        If you cannot locate your UTR number through these documents or online
        accounts, you may contact HMRC directly for assistance. HMRC will
        require proof of identity before disclosing your UTR number to ensure
        confidentiality. You can reach HMRC through their helpline or by
        visiting a local tax office. Ensure you have your National Insurance
        number and personal details ready to expedite the verification process.
        For further guidance and support, refer to the official HMRC website or
        contact their customer service.
      </Text>
    </Group>
  );
};

export default UKTaxFAQ;
