import { Table, Text } from "@mantine/core";
import { IncomeTaxBreakdown } from "backend/src/types";
import React from "react";
import {
  gbpFormatter,
  getTaxBandName,
  getTaxBandRate,
  getTitle,
  percFormatter,
} from "../../utils";

type NITaxBandsBreakdownProps = {
  data: IncomeTaxBreakdown[];
};

type NITaxBandTableRow = {
  bandName: string;
  bandRate: string;
  taxAmount: string;
  monthlyTaxAmount: string;
  bandName2?: string;
  bandRate2?: string;
  taxAmount2?: string;
  monthlyTaxAmount2?: string;
};

const getTableRows = (
  incomeTaxBreakdown: IncomeTaxBreakdown
): NITaxBandTableRow[] => {
  return incomeTaxBreakdown.result.nationalInsuranceTaxBands.map(
    (band, index) => ({
      bandName: getTaxBandName(band),
      bandRate: getTaxBandRate(band),
      monthlyTaxAmount: gbpFormatter(
        incomeTaxBreakdown.result.nationalInsuranceTaxBreakdownMonthly[index] /
          100.0
      ),
      taxAmount: gbpFormatter(
        incomeTaxBreakdown.result.nationalInsuranceTaxBreakdown[index] / 100.0
      ),
    })
  );
};

export const NITaxBandsBreakdown: React.FC<NITaxBandsBreakdownProps> = ({
  data,
}) => {
  const salary1TaxBands: NITaxBandTableRow[] = getTableRows(data[0]);
  let taxBands: NITaxBandTableRow[], salary2TaxBands: NITaxBandTableRow[];

  if (data.length && data.length > 1) {
    salary2TaxBands = getTableRows(data[1]);
    // Warning: doesn't take into account when length of bands are mismatched
    taxBands = salary1TaxBands.map((element, index) => ({
      ...element,
      bandName2: salary2TaxBands[index].bandName,
      bandRate2: salary2TaxBands[index].bandRate,
      monthlyTaxAmount2: salary2TaxBands[index].monthlyTaxAmount,
      taxAmount2: salary2TaxBands[index].taxAmount,
    }));
  } else {
    taxBands = salary1TaxBands;
  }

  const rows = taxBands.map((taxBand) =>
    data.length > 1 ? (
      <Table.Tr key={`ni-tax-band-breakdown-${taxBand.bandName}`}>
        <Table.Td>{taxBand.bandName}</Table.Td>
        <Table.Td>{taxBand.bandRate}</Table.Td>
        <Table.Td>{taxBand.monthlyTaxAmount}</Table.Td>
        <Table.Td>{taxBand.taxAmount}</Table.Td>
        <Table.Td>{taxBand.bandName2}</Table.Td>
        <Table.Td>{taxBand.bandRate2}</Table.Td>
        <Table.Td>{taxBand.monthlyTaxAmount2}</Table.Td>
        <Table.Td>{taxBand.taxAmount2}</Table.Td>
      </Table.Tr>
    ) : (
      <Table.Tr key={`ni-tax-band-breakdown-${taxBand.bandName}`}>
        <Table.Td>{taxBand.bandName}</Table.Td>
        <Table.Td>{taxBand.bandRate}</Table.Td>
        <Table.Td>{taxBand.monthlyTaxAmount}</Table.Td>
        <Table.Td>{taxBand.taxAmount}</Table.Td>
      </Table.Tr>
    )
  );

  return (
    <Table.ScrollContainer mt="md" minWidth={190 + 290 * data.length}>
      <Table
        striped
        withTableBorder
        withColumnBorders
        highlightOnHover
        verticalSpacing="xs"
      >
        <Table.Thead>
          {data.length > 1 && (
            <Table.Tr>
              <Table.Th colSpan={4} w="50%">
                <Text fz="sm">{getTitle(data[0].inputs)}</Text>
              </Table.Th>
              <Table.Th colSpan={4} w="50%">
                <Text fz="sm">{getTitle(data[1].inputs)}</Text>
              </Table.Th>
            </Table.Tr>
          )}
          {data.length > 1 ? (
            <Table.Tr>
              <Table.Th>Band</Table.Th>
              <Table.Th>Rate</Table.Th>
              <Table.Th>Month</Table.Th>
              <Table.Th>Year</Table.Th>
              <Table.Th>Band</Table.Th>
              <Table.Th>Rate</Table.Th>
              <Table.Th>Month</Table.Th>
              <Table.Th>Year</Table.Th>
            </Table.Tr>
          ) : (
            <Table.Tr>
              <Table.Th>Band</Table.Th>
              <Table.Th>Rate</Table.Th>
              <Table.Th>Month</Table.Th>
              <Table.Th>Year</Table.Th>
            </Table.Tr>
          )}
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
        <Table.Tfoot>
          {data.length > 1 ? (
            <Table.Tr>
              <Table.Th>Total</Table.Th>
              <Table.Th>
                {data[0].result.grossIncome > 0 &&
                  percFormatter(
                    data[0].result.nationalInsuranceTax /
                      data[0].result.grossIncome
                  )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(
                  data[0].result.nationalInsuranceTaxMonthly / 100.0
                )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(data[0].result.nationalInsuranceTax / 100.0)}
              </Table.Th>
              <Table.Th>Total</Table.Th>
              <Table.Th>
                {data[1].result.grossIncome > 0 &&
                  percFormatter(
                    data[1].result.nationalInsuranceTax /
                      data[1].result.grossIncome
                  )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(
                  data[1].result.nationalInsuranceTaxMonthly / 100.0
                )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(data[1].result.nationalInsuranceTax / 100.0)}
              </Table.Th>
            </Table.Tr>
          ) : (
            <Table.Tr>
              <Table.Th>Total</Table.Th>
              <Table.Th>
                {data[0].result.grossIncome > 0 &&
                  percFormatter(
                    data[0].result.nationalInsuranceTax /
                      data[0].result.grossIncome
                  )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(
                  data[0].result.nationalInsuranceTaxMonthly / 100.0
                )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(data[0].result.nationalInsuranceTax / 100.0)}
              </Table.Th>
            </Table.Tr>
          )}
        </Table.Tfoot>
      </Table>
    </Table.ScrollContainer>
  );
};
