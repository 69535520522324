import { createTheme } from "@mantine/core";

export const theme = createTheme({
  defaultGradient: {
    from: "orange",
    to: "red",
    deg: 45,
  },
  primaryColor: "orange",
  components: {
    Menu: {
      styles: {
        item: {
          backgroundColor: "rgba(0,0,0,0.5)",
          marginTop: "2rem",
        },
        itemLabel: {
          color: "orange",
        },
      },
    },
  },
});
