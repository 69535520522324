import { Button, Group, Radio, Slider, Stepper } from "@mantine/core";
import { LooseKeys } from "@mantine/form/lib/types";
import { useDebouncedValue } from "@mantine/hooks";
import React from "react";
import {
  IncomeFormValues,
  useIncomeFormContext,
} from "../../context/form-context";
import { gbpFormatter } from "../../utils";

type ConfigurePensionStepperProps = {
  onFinish: () => void;
};

const ConfigurePensionStepper: React.FC<ConfigurePensionStepperProps> = ({
  onFinish,
}) => {
  const form = useIncomeFormContext();
  const currentPath = form.values.currentPath;
  const [active, setActive] = React.useState(0);
  const [pensionContributions, setPensionContributions] =
    React.useState<number>(form.values[currentPath].pensionContributions || 0);
  const [employerPensionContributions, setEmployerPensionContributions] =
    React.useState<number>(
      form.values[currentPath].employerPensionContributions || 0
    );
  const [debouncedPensionContributions] = useDebouncedValue(
    pensionContributions,
    400
  );
  const [debouncedEmployerPensionContributions] = useDebouncedValue(
    employerPensionContributions,
    400
  );
  React.useEffect(() => {
    updateFieldValue(
      `${currentPath}.pensionContributions`,
      debouncedPensionContributions
    );
  }, [currentPath, debouncedPensionContributions]);
  React.useEffect(() => {
    updateFieldValue(
      `${currentPath}.employerPensionContributions`,
      debouncedEmployerPensionContributions
    );
  }, [currentPath, debouncedEmployerPensionContributions]);
  const skipStep = () => {
    if (active < 4) {
      setActive((current) => current + 1);
    } else {
      onFinish();
    }
  };
  const updateFieldValue = (
    path: LooseKeys<IncomeFormValues>,
    value: string | number,
    nextStep: boolean = false
  ) => {
    form.setFieldValue(path, value);
    if (nextStep) {
      skipStep();
    }
  };
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="Pension type" description="Set pension type">
          <Radio.Group
            name="pensionType"
            label="Pension type"
            withAsterisk
            onChange={(value) =>
              updateFieldValue(`${currentPath}.pensionType`, value, true)
            }
            defaultValue="Salary Sacrifice"
            value={form.values[currentPath].pensionType || "Salary Sacrifice"}
          >
            <Group mt="xs">
              <Radio value="Auto Enrolment" label="Auto Enrolment" />
              <Radio value="Employer" label="Employer" />
              <Radio value="Salary Sacrifice" label="Salary Sacrifice" />
              <Radio value="Personal" label="Personal" />
            </Group>
          </Radio.Group>
        </Stepper.Step>
        <Stepper.Step label="% or £" description="Set contribution type">
          <Radio.Group
            name="pensionContributionsType"
            label="Pension contribution type"
            withAsterisk
            onChange={(value) =>
              updateFieldValue(
                `${currentPath}.pensionContributionsType`,
                value,
                true
              )
            }
            defaultValue="Percentage"
            value={
              form.values[currentPath].pensionContributionsType || "Percentage"
            }
          >
            <Group mt="xs">
              <Radio value="Percentage" label="Percentage" />
              <Radio value="Nominal" label="Nominal" />
            </Group>
          </Radio.Group>
        </Stepper.Step>
        {form.values[currentPath].pensionContributionsType === "Nominal" ? (
          <Stepper.Step
            label="Employee amount"
            description="Set contribution amount"
          >
            <Slider
              labelAlwaysOn
              label={(value) =>
                Intl.NumberFormat("en-GB", {
                  style: "currency",
                  currency: "GBP",
                  signDisplay: "never",
                }).format(value)
              }
              color="blue"
              marks={
                form.values[currentPath].grossIncome
                  ? [
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.01,
                        label: "1%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.02,
                        label: "2%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.03,
                        label: "3%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.04,
                        label: "4%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.05,
                        label: "5%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.06,
                        label: "6%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.07,
                        label: "7%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.08,
                        label: "8%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.09,
                        label: "9%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.1,
                        label: "10%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.15,
                        label: "15%",
                      },
                      {
                        value:
                          (form.values[currentPath].grossIncome || 0) * 0.2,
                        label: "20%",
                      },
                    ]
                  : [
                      {
                        value: 2000,
                        label: gbpFormatter(2000),
                      },
                      {
                        value: 4000,
                        label: gbpFormatter(4000),
                      },
                      {
                        value: 6000,
                        label: gbpFormatter(6000),
                      },
                      {
                        value: 8000,
                        label: gbpFormatter(8000),
                      },
                    ]
              }
              min={0}
              max={(form.values[currentPath].grossIncome || 50000) * 0.2}
              defaultValue={(form.values[currentPath].grossIncome || 0) * 0.05}
              thumbSize={22}
              value={pensionContributions}
              onChange={(value) => setPensionContributions(value)}
            />
          </Stepper.Step>
        ) : (
          <Stepper.Step
            label="Employee amount"
            description="Set contribution amount"
            value={form.values[currentPath].pensionContributions || 5}
          >
            <Slider
              labelAlwaysOn
              label={(value) => `${value}%`}
              color="blue"
              marks={[
                { value: 1, label: "1%" },
                { value: 2, label: "2%" },
                { value: 3, label: "3%" },
                { value: 4, label: "4%" },
                { value: 5, label: "5%" },
                { value: 6, label: "6%" },
                { value: 7, label: "7%" },
                { value: 8, label: "8%" },
                { value: 9, label: "9%" },
                { value: 10, label: "10%" },
                { value: 15, label: "15%" },
                { value: 20, label: "20%" },
              ]}
              min={0}
              max={20}
              defaultValue={5}
              thumbSize={22}
              value={pensionContributions}
              onChange={(value) => setPensionContributions(value)}
            />
          </Stepper.Step>
        )}
        <Stepper.Step
          label="Employer amount"
          description="Set employer contribution"
          value={form.values[currentPath].employerPensionContributions}
        >
          <Slider
            labelAlwaysOn
            label={(value) => `${value}%`}
            color="blue"
            marks={[
              { value: 1, label: "1%" },
              { value: 2, label: "2%" },
              { value: 3, label: "3%" },
              { value: 4, label: "4%" },
              { value: 5, label: "5%" },
              { value: 6, label: "6%" },
              { value: 7, label: "7%" },
              { value: 8, label: "8%" },
              { value: 9, label: "9%" },
              { value: 10, label: "10%" },
              { value: 15, label: "15%" },
              { value: 20, label: "20%" },
            ]}
            min={0}
            max={20}
            defaultValue={3}
            thumbSize={22}
            value={employerPensionContributions || 3}
            onChange={(value) => setEmployerPensionContributions(value)}
          />
        </Stepper.Step>
        <Stepper.Completed>
          Completed, click back button to get to previous step
        </Stepper.Completed>
      </Stepper>
      <Group justify="center" mt="xl">
        <Button variant="default" onClick={prevStep}>
          Back
        </Button>
        <Button onClick={skipStep}>
          {active < 4 ? "Next step" : "Finish"}
        </Button>
      </Group>
    </>
  );
};

export default ConfigurePensionStepper;
