import { Grid, Group, Radio, Select, Text, TextInput } from "@mantine/core";
import { NITaxCategory, SupportedTaxYear } from "backend/src/types";
import React from "react";
import { useIncomeFormContext } from "../../context/form-context";

type ConfigureTaxSettingsProps = {};

type TaxItem = {
  value: SupportedTaxYear;
  label: string;
  description: string;
};

export const taxSettings: TaxItem[] = [
  {
    value: "24-25",
    label: "2024/25",
    description:
      "Tax rates from April 2024. Reduction in personal N.I. rates between £1,048 and £4,189 per month, from 10% to 8%. Increase in Blind Persons' Allowance, from £2,870 to £3,070 annually.",
  },
  {
    value: "23-24-jan",
    label: "2023/24 January",
    description:
      "Tax rates from January 2024. Reduction in personal N.I. rates between £1,048 and £4,189 per month, from 12% to 10%.",
  },
  {
    value: "23-24",
    label: "2023/24",
    description:
      "Tax rates from April 2023 until January 2024. Reduction in higher rate cap from £150,000 to £125,140. Increase in Blind Persons' Allowance, from £2,600 to £2,870 annually.",
  },
  {
    value: "22-23-nov",
    label: "2022/23 November",
    description:
      "Tax rates from November 2022 until April 2023. Reduction in personal N.I. rates above £4,189 per month from 3.25% to 2%.",
  },
  {
    value: "22-23-jul",
    label: "2022/23 July",
    description:
      "Tax rates from July 2022 until November 2022. Increase in N.I. primary threshold from £823 per month to £1,048 per month -- saving up to £29.81 in personal N.I. tax.",
  },
  {
    value: "22-23",
    label: "2022/23",
    description:
      "Tax rates from April 2022 until July 2022. Increase in N.I. primary threshold from £797 per month to £823 per month. Increase in personal N.I. rates above the primary threshold by 1.25%.",
  },
  {
    value: "21-22",
    label: "2021/22",
    description:
      "Tax rates from April 2021 until April 2022. Increase in income tax annual basic rate cap from £50,000 to £50,270. Increase in N.I. primary threshold and upper earnings limit from £792 to £797 and from £4,167 to £4,189 per month, respectively.",
  },
  {
    value: "20-21",
    label: "2020/21",
    description: "Tax rates from April 2020 until April 2021.",
  },
];

const SelectedTaxItem: React.FC<{ taxYear: SupportedTaxYear | undefined }> = ({
  taxYear,
}) => {
  const taxItem = taxSettings.find(
    (taxSetting) => taxSetting.value === taxYear
  );
  if (!taxItem) {
    return <Text>Select a tax year.</Text>;
  }
  const { label, description } = taxItem;
  return (
    <div>
      <Text fz="xs" fw={500}>
        Selected: {label}
      </Text>
      <Text fz="xs" opacity={0.6}>
        {description}
      </Text>
    </div>
  );
};

const nationalInsuranceTaxCategories = [
  "A",
  "B",
  "C",
  "F",
  "H",
  "I",
  "J",
  "L",
  "M",
  "S",
  "V",
  "Z",
];

const ConfigureTaxSettings: React.FC<ConfigureTaxSettingsProps> = () => {
  const form = useIncomeFormContext();
  const currentPath = form.values.currentPath;
  return (
    <>
      <Grid>
        <Grid.Col span={{ base: 12, xs: 6 }}>
          <Select
            name="taxYear"
            label="Tax Year"
            description="Amend personal allowances and tax rates for the tax year"
            withAsterisk
            placeholder="Select tax year"
            data={taxSettings}
            defaultValue={taxSettings[0].value}
            value={form.values[currentPath].taxYear}
            onChange={(value) =>
              form.setFieldValue(
                `${currentPath}.taxYear`,
                value as SupportedTaxYear
              )
            }
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6 }}>
          <SelectedTaxItem taxYear={form.values[currentPath].taxYear} />
        </Grid.Col>
      </Grid>
      <Radio.Group
        name="niTaxCategory"
        label="N.I. Category"
        description="Amend national insurance rates"
        withAsterisk
        mt="lg"
        onChange={(value) =>
          form.setFieldValue(
            `${currentPath}.niTaxCategory`,
            value as NITaxCategory
          )
        }
        defaultValue={"A"}
        value={form.values[currentPath].niTaxCategory}
      >
        <Group mt="xs">
          {nationalInsuranceTaxCategories.map((value) => (
            <Radio
              key={`ni-cat-${value}`}
              value={value}
              label={`Cat ${value}`}
            />
          ))}
        </Group>
      </Radio.Group>
      <TextInput
        name="taxCode"
        label="Tax Code (optional)"
        description="This will override your personal allowance settings"
        mt="lg"
        value={form.values[currentPath].taxCode || ""}
        onChange={(event) =>
          form.setFieldValue(`${currentPath}.taxCode`, event.target.value)
        }
      />
    </>
  );
};

export default ConfigureTaxSettings;
