import { createFormActions, createFormContext } from "@mantine/form";
import { IncomeDetails } from "backend/src/types";

export const INCOME_FORM_NAME = "income-form";

export interface IncomeFormValues {
  salary1: Partial<IncomeDetails>;
  salary2: Partial<IncomeDetails>;
  currentPath: "salary1" | "salary2";
}

// You can give context variables any name
export const [IncomeFormProvider, useIncomeFormContext, useIncomeForm] =
  createFormContext<IncomeFormValues>();

export const incomeFormActions =
  createFormActions<IncomeFormValues>(INCOME_FORM_NAME);
