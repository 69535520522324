import { Container, Group, Text } from "@mantine/core";
import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <Group mt="xl" grow>
      <Container size="sm">
        <Text>Privacy Policy for income-tax-calculator.com</Text>
        <Text>Last updated: Thu 7 Dec 2023</Text>

        <Text>
          Thank you for using income-tax-calculator.com! This Privacy Policy
          explains how we collect, use, and disclose information when you use
          our website. By accessing or using income-tax-calculator.com, you
          agree to the terms of this Privacy Policy.
        </Text>

        <Text>1. Information We Collect</Text>
        <Text>1.1 Personal Information</Text>
        <Text>
          We do not collect or store any personal information from our users. We
          are committed to protecting your privacy and ensuring a secure online
          experience.
        </Text>

        <Text>1.2 Financial Information</Text>
        <Text>
          income-tax-calculator.com may request income-related information
          solely for the purpose of calculating taxes. This information is
          processed on-the-fly, and we do not retain any financial details on
          our servers.
        </Text>

        <Text>2. Cookies and Tracking Technologies</Text>
        <Text>
          We use cookies and similar tracking technologies to enhance your
          experience on income-tax-calculator.com. These technologies may
          collect non-personally identifiable information to improve our
          services and deliver targeted advertisements. You can manage your
          cookie preferences through your browser settings.
        </Text>

        <Text>3. Google Ads Consent</Text>
        <Text>3.1 Targeted Ads</Text>
        <Text>
          income-tax-calculator.com uses Google Ads services to display targeted
          advertisements. These ads are tailored based on non-personally
          identifiable information, such as your browsing behavior and
          preferences.
        </Text>

        <Text>3.2 Google Ads Personalization</Text>
        <Text>
          By using income-tax-calculator.com, you consent to the personalization
          of ads provided by Google Ads. You can customize your ad preferences
          and opt-out of personalized ads through Google's Ads Settings.
        </Text>

        <Text>4. Data Security</Text>
        <Text>
          We employ industry-standard security measures to protect your
          information. However, please note that no method of transmission over
          the Internet or electronic storage is entirely secure. While we strive
          to use commercially acceptable means to protect your information, we
          cannot guarantee its absolute security.
        </Text>

        <Text>5. Third-Party Links</Text>
        <Text>
          income-tax-calculator.com may contain links to third-party websites.
          We are not responsible for the privacy practices or content of these
          websites. We recommend reviewing the privacy policies of any
          third-party sites you visit.
        </Text>

        <Text>6. Changes to this Privacy Policy</Text>
        <Text>
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective immediately upon posting the
          revised Privacy Policy on income-tax-calculator.com. Your continued
          use of the website after any changes to the Privacy Policy constitutes
          acceptance of those changes.
        </Text>

        <Text>7. Contact Us</Text>
        <Text>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at info at income-tax-calculator.com.
        </Text>

        <Text>
          By using income-tax-calculator.com, you acknowledge that you have read
          and understood this Privacy Policy and agree to its terms.
        </Text>
      </Container>
    </Group>
  );
};

export default PrivacyPolicy;
