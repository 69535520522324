import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppShell } from "./components/AppShell/AppShell";
import {
  INCOME_FORM_NAME,
  IncomeFormProvider,
  useIncomeForm,
} from "./context/form-context";
import DeepLinkCalculatorResult from "./pages/deep-link-calculator-result";
import Home from "./pages/home";
import PrivacyPolicy from "./pages/privacy-policy";
import UKTaxFAQ from "./pages/tax-faq";

const history = createBrowserHistory();
const app = document.getElementById("root");
if (app) {
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }
}

const App = () => {
  const incomeForm = useIncomeForm({
    name: INCOME_FORM_NAME,
    initialValues: {
      currentPath: "salary1",
      salary1: {
        grossIncome: undefined,
        pensionContributions: 0,
        employerPensionContributions: 0,
        pensionContributionsType: "Percentage",
        studentLoanPlans: [],
        giftAid: 0,
        isBlind: false,
        taxYear: "24-25",
        niTaxCategory: "A",
        taxCode: "",
      },
      salary2: {
        grossIncome: undefined,
        pensionContributions: 0,
        employerPensionContributions: 0,
        pensionContributionsType: "Percentage",
        studentLoanPlans: [],
        giftAid: 0,
        isBlind: false,
        taxYear: "24-25",
        niTaxCategory: "A",
        taxCode: "",
      },
    },
    transformValues: (values) => ({
      ...values,
      salary1: {
        ...values.salary1,
        pensionContributions:
          values.salary1.pensionContributionsType === "Percentage"
            ? (values.salary1.pensionContributions || 0) / 100.0
            : values.salary1.pensionContributions,
        employerPensionContributions:
          (values.salary1.employerPensionContributions || 0) / 100.0,
      },
      salary2: {
        ...values.salary2,
        pensionContributions:
          values.salary2.pensionContributionsType === "Percentage"
            ? (values.salary2.pensionContributions || 0) / 100.0
            : values.salary2.pensionContributions,
        employerPensionContributions:
          (values.salary2.employerPensionContributions || 0) / 100.0,
      },
    }),
  });

  return (
    <IncomeFormProvider form={incomeForm}>
      <BrowserRouter basename="/">
        <AppShell>
          <Routes>
            <Route path="/privacy-policy" Component={PrivacyPolicy} />
            <Route
              path="/calculator/:slug?"
              Component={DeepLinkCalculatorResult}
            />
            <Route path="/uk-tax-faq" Component={UKTaxFAQ} />
            <Route path="*" Component={Home} />
          </Routes>
        </AppShell>
      </BrowserRouter>
    </IncomeFormProvider>
  );
};

export default App;
