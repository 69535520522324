import React from "react";
import { Accordion, Button, Group, Modal } from "@mantine/core";
import {
  IconDiscountCheck,
  IconPigMoney,
  IconSettings,
} from "@tabler/icons-react";
import { useIncomeFormContext } from "../../context/form-context";
import ConfigureAllowancesMultiselect from "../ConfigureAllowancesMultiselect/ConfigureAllowancesMultiselect";
import ConfigurePensionStepper from "../ConfigurePensionStepper/ConfigurePensionStepper";
import ConfigureTaxSettings from "../ConfigureTaxSettings/ConfigureTaxSettings";

type ConfigureSalaryModalProps = {
  opened: boolean;
  onClose: () => void;
};

const ConfigureSalaryModal: React.FC<ConfigureSalaryModalProps> = ({
  opened,
  onClose,
}) => {
  const form = useIncomeFormContext();
  const [accordionItem, setAccordionItem] = React.useState<string | null>(
    "Allowances & Deductions"
  );

  const onFinishPensionSetup = () => {
    setAccordionItem("Tax Settings");
  };

  const options = [
    {
      icon: <IconDiscountCheck />,
      title: "Allowances & Deductions",
      description: <ConfigureAllowancesMultiselect />,
    },
    {
      icon: <IconPigMoney />,
      title: "Pension Contributions",
      description: <ConfigurePensionStepper onFinish={onFinishPensionSetup} />,
    },
    {
      icon: <IconSettings />,
      title: "Tax Settings",
      description: <ConfigureTaxSettings />,
    },
  ];

  const items = options.map((option) => (
    <Accordion.Item key={option.title} value={option.title}>
      <Accordion.Control icon={option.icon}>{option.title}</Accordion.Control>
      <Accordion.Panel>{option.description}</Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Modal
      size="xl"
      opened={opened}
      onClose={onClose}
      title={`Configure Salary ${form.values.currentPath.slice(-1)}`}
      centered
      transitionProps={{
        transition: "fade",
        duration: 600,
        timingFunction: "linear",
      }}
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
    >
      <Accordion
        value={accordionItem}
        onChange={setAccordionItem}
        defaultValue="Allowances & Deductions"
      >
        {items}
      </Accordion>

      <Group mt="xl" justify="center">
        <Button onClick={onClose}>Done</Button>
      </Group>
    </Modal>
  );
};

export default ConfigureSalaryModal;
