import { Button, List, Pill, Text, rem } from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import React from "react";

type InstructionsContentProps = {};

const InstructionsContent: React.FC<InstructionsContentProps> = () => {
  return (
    <>
      <List mb="xl" type="ordered" spacing="lg">
        <List.Item>
          <Text>Enter your salary into the salary input field.</Text>
        </List.Item>
        <List.Item>
          <Text>
            Press the{" "}
            <Button
              size="xs"
              radius="xl"
              rightSection={
                <IconSettings
                  style={{ width: rem(12), height: rem(12) }}
                  stroke={1.5}
                />
              }
            >
              Configure
            </Button>{" "}
            button to set your tax allowances, deductions, and tax settings.
          </Text>
        </List.Item>
        <List.Item>
          <Text>
            Compare two salaries by entering a second salary.{" "}
            <Pill>Optional</Pill>
          </Text>
        </List.Item>
      </List>
    </>
  );
};

export default InstructionsContent;
