import { IncomeDetails, IncomeTaxBreakdown } from "backend/src/types";
import React from "react";
import { TableSalaries } from "../components/TableSalaries/TableSalaries";
import WelcomeContent from "../components/WelcomeContent/WelcomeContent";
import { useDocumentTitle } from "@mantine/hooks";

const IncomeTaxCalculator: React.FC = () => {
  useDocumentTitle(
    "Income tax, Pension deductions and Take-home pay Calculator"
  );
  const [exampleData, setExampleData] = React.useState<IncomeTaxBreakdown[]>();

  React.useEffect(() => {
    const exampleSalaries: Partial<IncomeDetails>[] = [
      {
        grossIncome: 35000,
        pensionContributions: 0.05,
        studentLoanPlans: ["Plan 2"],
        giftAid: 0,
        isBlind: false,
        taxYear: "24-25",
      },
      {
        grossIncome: 45000,
        pensionContributions: 0.05,
        studentLoanPlans: ["Plan 2"],
        giftAid: 0,
        isBlind: false,
        taxYear: "24-25",
      },
    ];
    fetch(
      `${process.env.REACT_APP_INCOME_TAX_CALCULATOR_API_URL}/compare-salaries`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          salaries: exampleSalaries,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => setExampleData(data));
  }, []);

  const exampleTable = React.useMemo(() => {
    if (typeof exampleData !== "undefined" && exampleData.length > 0) {
      return <TableSalaries data={exampleData} />;
    }
    return null;
  }, [exampleData]);

  return (
    <WelcomeContent calculatorLink="/calculator" exampleTable={exampleTable} />
  );
};

export default IncomeTaxCalculator;
