import React from "react";
import { CopyButton, Button } from "@mantine/core";

type ShareDeepLinkButtonProps = {
  url: string;
};

const ShareDeepLinkButton: React.FC<ShareDeepLinkButtonProps> = ({ url }) => {
  return (
    <CopyButton value={url}>
      {({ copied, copy }) => (
        <Button
          variant="gradient"
          gradient={
            copied
              ? { from: "teal", to: "blue" }
              : { from: "orange", to: "red" }
          }
          onClick={copy}
          radius="xl"
        >
          {copied ? "Copied url" : "Copy url"}
        </Button>
      )}
    </CopyButton>
  );
};

export default ShareDeepLinkButton;
