import {
  Burger,
  Group,
  AppShell as MantineAppShell,
  Menu,
  ScrollArea,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconCalculator,
  IconChevronRight,
  IconInfoCircle,
  IconStar,
} from "@tabler/icons-react";
import React from "react";
import Logo from "../../assets/IncomeTaxCalculatorLogo";
import SlimLogo from "../../assets/IncomeTaxCalculatorSlimLogo";
import { Link } from "react-router-dom";

type AppShellProps = {
  children: React.ReactNode;
};

export const AppShell: React.FC<AppShellProps> = ({ children }) => {
  const [opened, { toggle }] = useDisclosure();

  return (
    <MantineAppShell
      layout="alt"
      header={{ height: { base: 60, sm: 60, lg: 15 } }}
      navbar={{ width: 300, breakpoint: "lg", collapsed: { mobile: !opened } }}
      aside={{
        width: 300,
        breakpoint: "lg",
        collapsed: { desktop: false, mobile: true },
      }}
      padding="md"
    >
      <MantineAppShell.Header hiddenFrom="lg" bg="dark">
        <Group h="100%" px="md">
          <Burger
            opened={opened}
            onClick={toggle}
            hiddenFrom="lg"
            size="sm"
            color="orange"
          />
          <SlimLogo uniqueSvgId="itc-mobile-header-logo" />
        </Group>
      </MantineAppShell.Header>
      <MantineAppShell.Navbar p="md" bg="dark">
        <Menu>
          <MantineAppShell.Section>
            <Group>
              <Menu.Target>
                <Burger
                  opened={opened}
                  onClick={toggle}
                  hiddenFrom="lg"
                  size="sm"
                  color="orange"
                  name="toggle menu"
                />
              </Menu.Target>
              <Logo uniqueSvgId="itc-desktop-logo" />
            </Group>
          </MantineAppShell.Section>
          <MantineAppShell.Section grow>
            <Menu.Item
              component={Link}
              to="/"
              onClick={toggle}
              title="Features"
              leftSection={<IconStar size="1rem" stroke={1.5} color="orange" />}
              rightSection={
                <IconChevronRight
                  size="0.8rem"
                  stroke={1.5}
                  className="mantine-rotate-rtl"
                  color="orange"
                />
              }
            >
              Features
            </Menu.Item>
            <Menu.Item
              mt="xs"
              component={Link}
              to="/calculator"
              onClick={toggle}
              title="Income Tax Calculator"
              leftSection={
                <IconCalculator size="1rem" stroke={1.5} color="orange" />
              }
              rightSection={
                <IconChevronRight
                  size="0.8rem"
                  stroke={1.5}
                  className="mantine-rotate-rtl"
                  color="orange"
                />
              }
            >
              Income Tax Calculator
            </Menu.Item>
            <Menu.Item
              mt="xs"
              component={Link}
              to="/uk-tax-faq"
              onClick={toggle}
              title="Tax FAQ"
              leftSection={
                <IconInfoCircle size="1rem" stroke={1.5} color="orange" />
              }
              rightSection={
                <IconChevronRight
                  size="0.8rem"
                  stroke={1.5}
                  className="mantine-rotate-rtl"
                  color="orange"
                />
              }
            >
              Tax FAQ
            </Menu.Item>
          </MantineAppShell.Section>
          <MantineAppShell.Section>
            <Text variant="gradient">
              <small>
                <a href="/privacy-policy">Privacy Policy</a>
              </small>
            </Text>
            <Text variant="gradient">
              <small>&copy; 2023-2024 Ozzypod Ltd.</small>
            </Text>
          </MantineAppShell.Section>
        </Menu>
      </MantineAppShell.Navbar>
      <MantineAppShell.Main>
        <MantineAppShell.Section pb="lg" hiddenFrom="lg">
          &nbsp;
        </MantineAppShell.Section>
        <MantineAppShell.Section>{children}</MantineAppShell.Section>
      </MantineAppShell.Main>
      <MantineAppShell.Aside p="md">
        <MantineAppShell.Section grow component={ScrollArea}>
          &nbsp;
        </MantineAppShell.Section>
      </MantineAppShell.Aside>
    </MantineAppShell>
  );
};
