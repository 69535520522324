import {
  IncomeDetails,
  IncomeTaxBreakdown,
  NITaxCategory,
  PensionContributionsType,
  PensionType,
  SupportedStudenLoanPlan,
  SupportedTaxYear,
  TaxBand,
} from "backend/src/types";
import { taxSettings } from "../components/ConfigureTaxSettings/ConfigureTaxSettings";

export const defaultDocumentTitle =
  "Calculate your income taxes and take-home pay, student loan repayments, pensions contributions, and more.";

export const gbpFormatter = Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
}).format;

export const percFormatter = Intl.NumberFormat("en-GB", {
  style: "percent",
}).format;

export const getPensionTitle = (input: IncomeDetails) => {
  let title = "";
  if (input.pensionContributions > 0) {
    if (input.pensionContributionsType === "Nominal") {
      title += `${gbpFormatter(input.pensionContributions / 100.0)} `;
    } else {
      title += `${percFormatter(input.pensionContributions)} `;
    }
    if (input.pensionType === "Salary Sacrifice") {
      title += "Salary Sacrifice Pension";
    } else {
      title += "Pension";
    }
  }
  return title;
};

export const getStudentLoanTitle = (input: IncomeDetails) => {
  let title = "";
  if (input.studentLoanPlans.length > 0) {
    title += `${input.studentLoanPlans.join(" + ")} Student Loan`;
  }
  return title;
};

export const getTitle = (input: IncomeDetails) => {
  const taxYear = taxSettings.find((tY) => tY.value === input.taxYear);
  let title = `${gbpFormatter(input.grossIncome / 100.0)} salary`;
  title += ` in ${taxYear?.label}`;
  title += ` (NI "${input.niTaxCategory || "A"}"`;
  if (input.isBlind) {
    title += `, Blind`;
  }
  if (input.giftAid) {
    title += `, ${gbpFormatter(input.giftAid / 100.0)} Gift Aid`;
  }
  for (let i = 0; i < input.studentLoanPlans.length; i++) {
    title += `, ${input.studentLoanPlans[i]}`;
  }
  if (input.pensionContributions > 0) {
    if (input.pensionContributionsType === "Nominal") {
      title += `, ${gbpFormatter(input.pensionContributions / 100.0)} `;
    } else {
      title += `, ${percFormatter(input.pensionContributions)} `;
    }
    if (input.pensionType === "Salary Sacrifice") {
      title += "Salary Sacrifice Pension";
    } else {
      title += "Pension";
    }
  }
  if (input.taxCode) {
    title += `, ${input.taxCode}`;
  }
  title += `)`;
  return title;
};

export const getMetaTitle = (data: IncomeTaxBreakdown[]) => {
  const salaryTitle =
    data.length === 2
      ? `Salary Comparison for ${getTitle(data[0].inputs)} vs ${getTitle(
          data[1].inputs
        )}`
      : `Take-home pay for ${getTitle(data[0].inputs)}`;
  return salaryTitle;
};

export const getSlug = (input: IncomeDetails): string => {
  let slug = `${input.grossIncome}${input.niTaxCategory}`;
  if (input.pensionContributions > 0) {
    const pensionAmount =
      input.pensionContributionsType === "Percentage"
        ? 100 * input.pensionContributions
        : input.pensionContributions;
    slug += `-${input.pensionContributionsType.charAt(
      0
    )}${pensionAmount}${input.pensionType.charAt(0).toUpperCase()}`;
  }
  if (input.employerPensionContributions) {
    slug += `-EP${100 * input.employerPensionContributions}`;
  }
  if (input.studentLoanPlans.length > 0) {
    slug += `-SL${input.studentLoanPlans
      .map((name) => name.slice(-1))
      .sort()
      .join("")}`;
  }
  if (input.isBlind) {
    slug += `-BA`;
  }
  if (input.giftAid) {
    slug += `-GA${input.giftAid}`;
  }
  if (input.taxCode) {
    slug += `-C${input.taxCode}`;
  }
  if (input.taxYear) {
    slug += `-Y${input.taxYear}`;
  }
  return slug;
};

export const parseSlug = (slug: string): Partial<IncomeDetails> => {
  const incomeDetails: Partial<IncomeDetails> = {};
  const incomeAndNI = slug.match(/(\d+)([ABCFHIJLMSVZ]{1})/);
  const pension = slug.match(/-([NP]{1})(\d+)([AESP]{1})/);
  const employerPension = slug.match(/-EP(\d+)/);
  const studentLoans = slug.match(/-SL(1)?(2)?(4)?(5)?(n)?/);
  const isBlind = slug.match(/-BA/);
  const giftAid = slug.match(/-GA(\d+)/);
  const taxCode = slug.match(/-C(\d{1,4}[^-]*)/);
  const taxYear = slug.match(/-Y(\d{2}-\d{2}(?:-[a-z]{3})?)/);
  const pensionContributionsTypeLookup: {
    [key: string]: PensionContributionsType;
  } = {
    N: "Nominal",
    P: "Percentage",
  };
  const pensionTypeLookup: { [key: string]: PensionType } = {
    A: "Auto Enrolment",
    E: "Employer",
    S: "Salary Sacrifice",
    P: "Personal",
  };
  const studentLoanLookup: { [key: string]: SupportedStudenLoanPlan } = {
    "1": "Plan 1",
    "2": "Plan 2",
    "4": "Plan 4",
    "5": "Plan 5",
    n: "Postgraduate Loan",
  };
  const taxYearLookup: { [key: string]: SupportedTaxYear } = {
    "20-21": "20-21",
    "21-22": "21-22",
    "22-23": "22-23",
    "22-23-jul": "22-23-jul",
    "22-23-nov": "22-23-nov",
    "23-24": "23-24",
    "23-24-jan": "23-24-jan",
    "24-25": "24-25",
  };
  if (incomeAndNI?.length === 3) {
    incomeDetails.grossIncome = parseInt(incomeAndNI[1]) / 100.0;
    incomeDetails.niTaxCategory = incomeAndNI[2] as NITaxCategory;
  }
  if (pension?.length === 4) {
    const pensionContributionsType = pensionContributionsTypeLookup[pension[1]];
    incomeDetails.pensionContributionsType = pensionContributionsType;
    if (incomeDetails.pensionContributionsType === "Nominal") {
      incomeDetails.pensionContributions = parseInt(pension[2]) / 100.0;
    } else {
      incomeDetails.pensionContributions = parseInt(pension[2]);
    }
    incomeDetails.pensionType = pensionTypeLookup[pension[3]];
  } else {
    incomeDetails.pensionContributionsType = "Percentage";
    incomeDetails.pensionContributions = 0;
    incomeDetails.pensionType = "Salary Sacrifice";
  }
  if (employerPension?.length === 2) {
    incomeDetails.employerPensionContributions = parseInt(employerPension[1]);
  }
  if (studentLoans?.length) {
    incomeDetails.studentLoanPlans = studentLoans
      ?.slice(1)
      .filter((value) => !!value)
      .map((studentLoanCode) => studentLoanLookup[studentLoanCode]);
  } else {
    incomeDetails.studentLoanPlans = [];
  }
  if (isBlind?.length) {
    incomeDetails.isBlind = true;
  } else {
    incomeDetails.isBlind = false;
  }
  if (giftAid?.length === 2) {
    incomeDetails.giftAid = parseInt(giftAid[1]) / 100.0;
  } else {
    incomeDetails.giftAid = 0;
  }
  if (taxCode?.length === 2) {
    incomeDetails.taxCode = taxCode[1];
  }
  if (taxYear?.length === 2) {
    incomeDetails.taxYear = taxYearLookup[taxYear[1]] || "24-25";
  }
  return incomeDetails;
};

export const getTaxBandName = (taxBand: TaxBand): string => {
  let bandName = `${gbpFormatter(taxBand.start / 100.0)}`;
  if (taxBand.end) {
    bandName += ` - ${gbpFormatter(taxBand.end / 100.0)}`;
  } else {
    bandName += "+";
  }
  return bandName;
};

export const getTaxBandRate = (taxBand: TaxBand): string => {
  return percFormatter(taxBand.rate);
};
