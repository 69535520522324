import { Text, Title } from "@mantine/core";
import { IncomeTaxBreakdown } from "backend/src/types";
import React from "react";
import {
  gbpFormatter,
  getPensionTitle,
  getStudentLoanTitle,
} from "../../utils";
import { taxSettings } from "../ConfigureTaxSettings/ConfigureTaxSettings";
import { useDocumentTitle } from "@mantine/hooks/lib/use-document-title/use-document-title";
import { useIsomorphicEffect } from "@mantine/hooks";

type IncomeDetailsSEOTextProps = {
  title?: string;
  incomeTaxBreakdown: IncomeTaxBreakdown;
};

export const IncomeDetailsSEOText: React.FC<IncomeDetailsSEOTextProps> = ({
  title,
  incomeTaxBreakdown,
}) => {
  const taxYear = taxSettings.find(
    (tY) => tY.value === incomeTaxBreakdown.inputs.taxYear
  );
  const grossIncome = gbpFormatter(
    incomeTaxBreakdown.result.grossIncome / 100.0
  );
  const grossIncomeMonthly = gbpFormatter(
    incomeTaxBreakdown.result.grossIncomeMonthly / 100.0
  );
  const grossIncomeDaily = gbpFormatter(
    incomeTaxBreakdown.result.grossIncomeDaily / 100.0
  );
  const grossIncomeHourly = gbpFormatter(
    incomeTaxBreakdown.result.grossIncomeHourly / 100.0
  );
  const incomeTax = gbpFormatter(incomeTaxBreakdown.result.incomeTax / 100.0);
  const incomeTaxMonthly = gbpFormatter(
    incomeTaxBreakdown.result.incomeTaxMonthly / 100.0
  );
  const incomeTaxDaily = gbpFormatter(
    incomeTaxBreakdown.result.incomeTaxDaily / 100.0
  );
  const incomeTaxHourly = gbpFormatter(
    incomeTaxBreakdown.result.incomeTaxHourly / 100.0
  );
  const nationalInsuranceTax = gbpFormatter(
    incomeTaxBreakdown.result.nationalInsuranceTax / 100.0
  );
  const nationalInsuranceTaxMonthly = gbpFormatter(
    incomeTaxBreakdown.result.nationalInsuranceTaxMonthly / 100.0
  );
  const nationalInsuranceTaxDaily = gbpFormatter(
    incomeTaxBreakdown.result.nationalInsuranceTaxDaily / 100.0
  );
  const nationalInsuranceTaxHourly = gbpFormatter(
    incomeTaxBreakdown.result.nationalInsuranceTaxHourly / 100.0
  );
  const tax = gbpFormatter(incomeTaxBreakdown.result.tax / 100.0);
  const taxMonthly = gbpFormatter(incomeTaxBreakdown.result.taxMonthly / 100.0);
  const taxDaily = gbpFormatter(incomeTaxBreakdown.result.taxDaily / 100.0);
  const taxHourly = gbpFormatter(incomeTaxBreakdown.result.taxHourly / 100.0);
  const netIncome = gbpFormatter(incomeTaxBreakdown.result.net / 100.0);
  const netIncomeMonthly = gbpFormatter(
    incomeTaxBreakdown.result.netMonthly / 100.0
  );
  const netIncomeDaily = gbpFormatter(
    incomeTaxBreakdown.result.netDaily / 100.0
  );
  const netIncomeHourly = gbpFormatter(
    incomeTaxBreakdown.result.netHourly / 100.0
  );
  const hasPensionDeductions = incomeTaxBreakdown.result.pensionDeductions > 0;
  const pensionDeductions = gbpFormatter(
    incomeTaxBreakdown.result.pensionDeductions / 100.0
  );
  const pensionDeductionsMonthly = gbpFormatter(
    incomeTaxBreakdown.result.pensionDeductionsMonthly / 100.0
  );
  const pensionDeductionsDaily = gbpFormatter(
    incomeTaxBreakdown.result.pensionDeductionsDaily / 100.0
  );
  const pensionDeductionsHourly = gbpFormatter(
    incomeTaxBreakdown.result.pensionDeductionsHourly / 100.0
  );
  const studentLoanDeductionsAnnual =
    incomeTaxBreakdown.result.studentLoanDeductions.reduce(
      (acc, cur) => acc + cur.annualDeduction,
      0
    ) / 100.0;
  const studentLoanDeductions = gbpFormatter(studentLoanDeductionsAnnual);
  const studentLoanDeductionsMonthly = gbpFormatter(
    incomeTaxBreakdown.result.studentLoanDeductions.reduce(
      (acc, cur) => acc + cur.monthlyDeduction,
      0
    ) / 100.0
  );
  const studentLoanDeductionsDaily = gbpFormatter(
    incomeTaxBreakdown.result.studentLoanDeductions.reduce(
      (acc, cur) => acc + cur.dailyDeduction,
      0
    ) / 100.0
  );
  const studentLoanDeductionsHourly = gbpFormatter(
    incomeTaxBreakdown.result.studentLoanDeductions.reduce(
      (acc, cur) => acc + cur.hourlyDeduction,
      0
    ) / 100.0
  );
  const deductions = gbpFormatter(incomeTaxBreakdown.result.deductions / 100.0);
  const deductionsMonthly = gbpFormatter(
    incomeTaxBreakdown.result.deductionsMonthly / 100.0
  );
  const deductionsDaily = gbpFormatter(
    incomeTaxBreakdown.result.deductionsDaily / 100.0
  );
  const deductionsHourly = gbpFormatter(
    incomeTaxBreakdown.result.deductionsHourly / 100.0
  );
  const takeHome = gbpFormatter(incomeTaxBreakdown.result.takeHome / 100.0);
  const takeHomeMonthly = gbpFormatter(
    incomeTaxBreakdown.result.takeHomeMonthly / 100.0
  );
  const takeHomeDaily = gbpFormatter(
    incomeTaxBreakdown.result.takeHomeDaily / 100.0
  );
  const takeHomeHourly = gbpFormatter(
    incomeTaxBreakdown.result.takeHomeHourly / 100.0
  );

  const netIncomeText = `In the tax year ${taxYear?.label}, with a gross income of ${grossIncome} annually (${grossIncomeMonthly} per month, ${grossIncomeDaily} per day, ${grossIncomeHourly} per hour), the income tax due is ${incomeTax} (${incomeTaxMonthly} per month, ${incomeTaxDaily} per day, ${incomeTaxHourly} per hour). Additionally, you will pay ${nationalInsuranceTax} per year (${nationalInsuranceTaxMonthly} per month, ${nationalInsuranceTaxDaily} per day, ${nationalInsuranceTaxHourly} per hour) in national insurance contributions. This means your total tax liability is ${tax} (${taxMonthly} per month, ${taxDaily} per day, ${taxHourly} per hour), leaving you with a net income of ${netIncome} annually (${netIncomeMonthly} per month, ${netIncomeDaily} per day, ${netIncomeHourly} per hour).`;

  useIsomorphicEffect(() => {
    // @ts-ignore-next-line
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", netIncomeText);
  }, [netIncomeText]);

  return (
    <>
      {title && (
        <Title order={3} mt="md">
          {title}
        </Title>
      )}
      <Text mt="md">
        In the tax year {taxYear?.label}, with a gross income of {grossIncome}{" "}
        annually ({grossIncomeMonthly} per month, {grossIncomeDaily} per day,{" "}
        {grossIncomeHourly} per hour), the income tax due is {incomeTax} (
        {incomeTaxMonthly} per month, {incomeTaxDaily} per day,{" "}
        {incomeTaxHourly} per hour). Additionally, you will pay{" "}
        {nationalInsuranceTax} per year ({nationalInsuranceTaxMonthly} per
        month, {nationalInsuranceTaxDaily} per day, {nationalInsuranceTaxHourly}{" "}
        per hour) in national insurance contributions. This means your total tax
        liability is {tax} ({taxMonthly} per month, {taxDaily} per day,{" "}
        {taxHourly} per hour), leaving you with a net income of {netIncome}{" "}
        annually ({netIncomeMonthly} per month, {netIncomeDaily} per day,{" "}
        {netIncomeHourly} per hour).
      </Text>
      <Text mt="md">
        {hasPensionDeductions && (
          <>
            With a {getPensionTitle(incomeTaxBreakdown.inputs)} the employee
            pension contributions are {pensionDeductions} (
            {pensionDeductionsMonthly} per month, {pensionDeductionsDaily} per
            day, {pensionDeductionsHourly} per hour).{" "}
          </>
        )}
        {studentLoanDeductionsAnnual > 0 && (
          <>
            With a {getStudentLoanTitle(incomeTaxBreakdown.inputs)} the
            repayments for this salary will be {studentLoanDeductions} annually
            ({studentLoanDeductionsMonthly} per month,{" "}
            {studentLoanDeductionsDaily} per day, {studentLoanDeductionsHourly}{" "}
            per hour). This means that your total annual deductions are{" "}
            {deductions} ({deductionsMonthly} per month, {deductionsDaily} per
            day, {deductionsHourly} per hour), leaving you with a take home pay
            of {takeHome} ({takeHomeMonthly} per month, {takeHomeDaily} per day,{" "}
            {takeHomeHourly} per hour).{" "}
          </>
        )}
      </Text>
    </>
  );
};
