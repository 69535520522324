import {
  Button,
  Checkbox,
  Combobox,
  Group,
  Input,
  LoadingOverlay,
  NumberInput,
  Pill,
  PillsInput,
  rem,
  useCombobox,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconCoinPound } from "@tabler/icons-react";
import { useState } from "react";
import { useIncomeFormContext } from "../../context/form-context";
import classes from "./ConfigureAllowancesMultiselect.module.css";

const salaryOptions = [
  {
    title: "Personal Allowance",
    items: ["Blind Persons Allowance", "Gift Aid"],
  },
  {
    title: "Student Loan Deductions",
    items: ["Plan 1", "Plan 2", "Plan 4", "Plan 5", "Postgraduate Loan"],
  },
];

type ConfigureAllowancesMultiselectProps = {};

const ConfigureAllowancesMultiselect: React.FC<
  ConfigureAllowancesMultiselectProps
> = ({}) => {
  const form = useIncomeFormContext();
  const currentPath = form.values.currentPath;

  const [
    giftAidOverlayVisible,
    { open: openGiftAidOverlay, close: closeGiftAidOverlay },
  ] = useDisclosure(false);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const initialValues = [
    ...(form.values[currentPath].isBlind ? ["Blind Persons Allowance"] : []),
    ...(form.values[currentPath].giftAid ? ["Gift Aid"] : []),
    ...(form.values[currentPath].studentLoanPlans?.includes("Plan 1")
      ? ["Plan 1"]
      : []),
    ...(form.values[currentPath].studentLoanPlans?.includes("Plan 2")
      ? ["Plan 2"]
      : []),
    ...(form.values[currentPath].studentLoanPlans?.includes("Plan 4")
      ? ["Plan 4"]
      : []),
    ...(form.values[currentPath].studentLoanPlans?.includes("Plan 5")
      ? ["Plan 5"]
      : []),
    ...(form.values[currentPath].studentLoanPlans?.includes("Postgraduate Loan")
      ? ["Postgraduate Loan"]
      : []),
  ];

  const [value, setValue] = useState<string[]>(initialValues);

  const handleValueSelect = (value: string) => {
    switch (value) {
      case "Blind Persons Allowance":
        form.setFieldValue(`${currentPath}.isBlind`, true);
        break;
      case "Gift Aid":
        openGiftAidOverlay();
        break;
      case "Plan 1":
        form.setFieldValue(`${currentPath}.studentLoanPlans`, [
          ...(form.values[currentPath].studentLoanPlans || []),
          "Plan 1",
        ]);
        break;
      case "Plan 2":
        form.setFieldValue(`${currentPath}.studentLoanPlans`, [
          ...(form.values[currentPath].studentLoanPlans || []),
          "Plan 2",
        ]);
        break;
      case "Plan 4":
        form.setFieldValue(`${currentPath}.studentLoanPlans`, [
          ...(form.values[currentPath].studentLoanPlans || []),
          "Plan 4",
        ]);
        break;
      case "Plan 5":
        form.setFieldValue(`${currentPath}.studentLoanPlans`, [
          ...(form.values[currentPath].studentLoanPlans || []),
          "Plan 5",
        ]);
        break;
      case "Postgraduate Loan":
        form.setFieldValue(`${currentPath}.studentLoanPlans`, [
          ...(form.values[currentPath].studentLoanPlans || []),
          "Postgraduate Loan",
        ]);
        break;
    }
    setValue((current) =>
      current.includes(value) ? current : [...current, value]
    );
  };

  const handleValueRemove = (value: string) => {
    switch (value) {
      case "Blind Persons Allowance":
        form.setFieldValue(`${currentPath}.isBlind`, false);
        break;
      case "Gift Aid":
        form.setFieldValue(`${currentPath}.giftAid`, 0);
        break;
      case "Plan 1":
      case "Plan 2":
      case "Plan 4":
      case "Plan 5":
        form.setFieldValue(
          `${currentPath}.studentLoanPlans`,
          (form.values[currentPath].studentLoanPlans || [])?.filter(
            (val) => val !== value
          )
        );
        break;
      case "Postgraduate Loan":
        form.setFieldValue(
          `${currentPath}.studentLoanPlans`,
          (form.values[currentPath].studentLoanPlans || [])?.filter(
            (val) => val !== "Postgraduate Loan"
          )
        );
        break;
    }
    setValue((current) => current.filter((val) => val !== value));
  };

  const values = value.map((item) => (
    <Pill
      key={`pill-field-${item}`}
      withRemoveButton
      onRemove={() => handleValueRemove(item)}
    >
      {item}
    </Pill>
  ));

  const options = salaryOptions.map((category, idx) => (
    <Combobox.Group label={category.title} key={`salary-${idx}`}>
      {category.items.map((item) => (
        <Combobox.Option value={item} key={item} active={value.includes(item)}>
          <Group gap="sm">
            <Checkbox
              readOnly
              checked={value.includes(item)}
              aria-hidden
              tabIndex={-1}
              style={{ pointerEvents: "none" }}
            />
            <span>{item}</span>
            {item === "Gift Aid" && (
              <NumberInput
                name="giftAid"
                hideControls
                fixedDecimalScale
                decimalScale={2}
                allowNegative={false}
                thousandSeparator=","
                radius="xl"
                placeholder="Click to enter gift aid"
                min={0}
                rightSectionWidth={42}
                leftSection={
                  <IconCoinPound
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                }
                onClick={openGiftAidOverlay}
                value={form.values[currentPath].giftAid}
              />
            )}
          </Group>
        </Combobox.Option>
      ))}
    </Combobox.Group>
  ));

  return (
    <div>
      <LoadingOverlay
        visible={giftAidOverlayVisible}
        loaderProps={{
          children: (
            <>
              <NumberInput
                hideControls
                label="Gift Aid"
                description="Enter your annual gift aid amount"
                fixedDecimalScale
                decimalScale={2}
                allowNegative={false}
                thousandSeparator=","
                placeholder="Enter gift aid amount"
                size="md"
                leftSection={
                  <IconCoinPound
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                }
                value={form.values[currentPath].giftAid}
                onChange={(value) => {
                  form.setFieldValue(`${currentPath}.giftAid`, +value);
                }}
              />
              <Button mt="sm" onClick={closeGiftAidOverlay}>
                Save
              </Button>
            </>
          ),
          z: 1000,
        }}
      />
      <Combobox
        classNames={classes}
        store={combobox}
        onOptionSubmit={handleValueSelect}
        withinPortal={false}
      >
        <PillsInput pointer onClick={() => combobox.toggleDropdown()}>
          <Pill.Group>
            {values.length > 0 ? (
              values
            ) : (
              <Input.Placeholder>Pick one or more values</Input.Placeholder>
            )}

            <Combobox.EventsTarget>
              <PillsInput.Field
                type="hidden"
                onBlur={() => combobox.closeDropdown()}
                onKeyDown={(event) => {
                  if (event.key === "Backspace") {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>

        <Combobox.Options>
          {options.length === 0 ? (
            <Combobox.Empty>All options selected</Combobox.Empty>
          ) : (
            options
          )}
        </Combobox.Options>
      </Combobox>
    </div>
  );
};

export default ConfigureAllowancesMultiselect;
