import { Table, Text } from "@mantine/core";
import { IncomeTaxBreakdown } from "backend/src/types";
import React from "react";
import {
  gbpFormatter,
  getTaxBandName,
  getTaxBandRate,
  getTitle,
  percFormatter,
} from "../../utils";

type IncomeTaxBandsBreakdownProps = {
  data: IncomeTaxBreakdown[];
};

type IncomeTaxBandTableRow = {
  bandName: string;
  bandRate: string;
  taxAmount: string;
  monthlyTaxAmount: string;
  bandName2?: string;
  bandRate2?: string;
  taxAmount2?: string;
  monthlyTaxAmount2?: string;
};

const getTableRows = (
  incomeTaxBreakdown: IncomeTaxBreakdown
): IncomeTaxBandTableRow[] => {
  return incomeTaxBreakdown.result.incomeTaxBands.map((band, index) => ({
    bandName: getTaxBandName(band),
    bandRate: getTaxBandRate(band),
    taxAmount: gbpFormatter(
      incomeTaxBreakdown.result.incomeTaxBreakdown[index] / 100.0
    ),
    monthlyTaxAmount: gbpFormatter(
      incomeTaxBreakdown.result.incomeTaxBreakdownMonthly[index] / 100.0
    ),
  }));
};

export const IncomeTaxBandsBreakdown: React.FC<
  IncomeTaxBandsBreakdownProps
> = ({ data }) => {
  const salary1TaxBands: IncomeTaxBandTableRow[] = getTableRows(data[0]);
  let taxBands: IncomeTaxBandTableRow[],
    salary2TaxBands: IncomeTaxBandTableRow[];

  if (data.length && data.length > 1) {
    salary2TaxBands = getTableRows(data[1]);
    // Warning: doesn't take into account when length of bands are mismatched
    taxBands = salary1TaxBands.map((element, index) => ({
      ...element,
      bandName2: salary2TaxBands[index].bandName,
      bandRate2: salary2TaxBands[index].bandRate,
      taxAmount2: salary2TaxBands[index].taxAmount,
      monthlyTaxAmount2: salary2TaxBands[index].monthlyTaxAmount,
    }));
  } else {
    taxBands = salary1TaxBands;
  }

  const rows = taxBands.map((taxBand) =>
    data.length > 1 ? (
      <Table.Tr key={`tax-band-breakdown-${taxBand.bandName}`}>
        <Table.Td>{taxBand.bandName}</Table.Td>
        <Table.Td>{taxBand.bandRate}</Table.Td>
        <Table.Td>{taxBand.monthlyTaxAmount}</Table.Td>
        <Table.Td>{taxBand.taxAmount}</Table.Td>
        <Table.Td>{taxBand.bandName2}</Table.Td>
        <Table.Td>{taxBand.bandRate2}</Table.Td>
        <Table.Td>{taxBand.monthlyTaxAmount2}</Table.Td>
        <Table.Td>{taxBand.taxAmount2}</Table.Td>
      </Table.Tr>
    ) : (
      <Table.Tr key={`tax-band-breakdown-${taxBand.bandName}`}>
        <Table.Td>{taxBand.bandName}</Table.Td>
        <Table.Td>{taxBand.bandRate}</Table.Td>
        <Table.Td>{taxBand.monthlyTaxAmount}</Table.Td>
        <Table.Td>{taxBand.taxAmount}</Table.Td>
      </Table.Tr>
    )
  );

  return (
    <Table.ScrollContainer mt="md" minWidth={190 + 290 * data.length}>
      <Table
        striped
        withTableBorder
        withColumnBorders
        highlightOnHover
        verticalSpacing="xs"
      >
        <Table.Thead>
          {data.length > 1 && (
            <Table.Tr>
              <Table.Th colSpan={4} w="50%">
                <Text fz="sm">{getTitle(data[0].inputs)}</Text>
              </Table.Th>
              <Table.Th colSpan={4} w="50%">
                <Text fz="sm">{getTitle(data[1].inputs)}</Text>
              </Table.Th>
            </Table.Tr>
          )}
          {data.length > 1 ? (
            <Table.Tr>
              <Table.Th>Band</Table.Th>
              <Table.Th>Rate</Table.Th>
              <Table.Th>Month</Table.Th>
              <Table.Th>Year</Table.Th>
              <Table.Th>Band</Table.Th>
              <Table.Th>Rate</Table.Th>
              <Table.Th>Month</Table.Th>
              <Table.Th>Year</Table.Th>
            </Table.Tr>
          ) : (
            <Table.Tr>
              <Table.Th>Band</Table.Th>
              <Table.Th>Rate</Table.Th>
              <Table.Th>Month</Table.Th>
              <Table.Th>Year</Table.Th>
            </Table.Tr>
          )}
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
        <Table.Tfoot>
          {data.length > 1 ? (
            <Table.Tr>
              <Table.Th>Total</Table.Th>
              <Table.Th>
                {data[0].result.grossIncome > 0 &&
                  percFormatter(
                    data[0].result.incomeTax / data[0].result.grossIncome
                  )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(data[0].result.incomeTaxMonthly / 100.0)}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(data[0].result.incomeTax / 100.0)}
              </Table.Th>
              <Table.Th>Total</Table.Th>
              <Table.Th>
                {data[1].result.grossIncome > 0 &&
                  percFormatter(
                    data[1].result.incomeTax / data[1].result.grossIncome
                  )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(data[1].result.incomeTaxMonthly / 100.0)}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(data[1].result.incomeTax / 100.0)}
              </Table.Th>
            </Table.Tr>
          ) : (
            <Table.Tr>
              <Table.Th>Total</Table.Th>
              <Table.Th>
                {data[0].result.grossIncome > 0 &&
                  percFormatter(
                    data[0].result.incomeTax / data[0].result.grossIncome
                  )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(data[0].result.incomeTaxMonthly / 100.0)}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(data[0].result.incomeTax / 100.0)}
              </Table.Th>
            </Table.Tr>
          )}
        </Table.Tfoot>
      </Table>
    </Table.ScrollContainer>
  );
};
