import { Table, Text } from "@mantine/core";
import { IncomeTaxBreakdown } from "backend/src/types";
import React from "react";
import { gbpFormatter, getTitle, percFormatter } from "../../utils";

type StudentLoanRepaymentsBreakdownProps = {
  data: IncomeTaxBreakdown[];
};

type StudentLoanRepaymentsBreakdownTableRow = {
  planName: string;
  repaymentThreshold: string;
  repaymentRate: string;
  repayment: string;
  repaymentMonthly: string;
  planName2?: string;
  repaymentThreshold2?: string;
  repaymentRate2?: string;
  repayment2?: string;
  repaymentMonthly2?: string;
};

const getTableRows = (
  incomeTaxBreakdown: IncomeTaxBreakdown
): StudentLoanRepaymentsBreakdownTableRow[] => {
  return incomeTaxBreakdown.result.studentLoanDeductions.map((plan) => ({
    planName: plan.planType,
    repaymentThreshold: gbpFormatter(plan.repaymentThreshold / 100.0),
    repaymentRate: percFormatter(plan.repaymentRate),
    repayment: gbpFormatter(plan.annualDeduction / 100.0),
    repaymentMonthly: gbpFormatter(plan.monthlyDeduction / 100.0),
  }));
};

export const StudentLoanRepaymentsBreakdown: React.FC<
  StudentLoanRepaymentsBreakdownProps
> = ({ data }) => {
  const salary1Repayments: StudentLoanRepaymentsBreakdownTableRow[] =
    getTableRows(data[0]);
  let repaymentBreakdown: StudentLoanRepaymentsBreakdownTableRow[] = [],
    salary2Repayments: StudentLoanRepaymentsBreakdownTableRow[];

  const mappingFunc = (
    plans: StudentLoanRepaymentsBreakdownTableRow[],
    index: number
  ) =>
    plans[index]
      ? {
          planName2: plans[index].planName,
          repaymentThreshold2: plans[index].repaymentThreshold,
          repaymentRate2: plans[index].repaymentRate,
          repaymentMonthly2: plans[index].repaymentMonthly,
          repayment2: plans[index].repayment,
        }
      : {};

  if (
    data.filter(
      (incomeTaxBreakDown) =>
        incomeTaxBreakDown.result.studentLoanDeductions.length > 0
    ).length > 1
  ) {
    salary2Repayments = getTableRows(data[1]);
    const totalRows = Math.max(
      data[1].result.studentLoanDeductions.length,
      data[0].result.studentLoanDeductions.length
    );
    for (let idx = 0; idx < totalRows; idx++) {
      repaymentBreakdown.push({
        ...(salary1Repayments[idx] || {}),
        ...mappingFunc(salary2Repayments, idx),
      });
    }
  } else {
    repaymentBreakdown = salary1Repayments;
  }

  const rows = repaymentBreakdown.map((studentLoanRepayment, idx) =>
    data.length > 1 ? (
      <Table.Tr
        key={`student-loan-repayment-${studentLoanRepayment.planName}-${idx}`}
      >
        <Table.Td>{studentLoanRepayment.planName}</Table.Td>
        <Table.Td>{studentLoanRepayment.repaymentThreshold}</Table.Td>
        <Table.Td>{studentLoanRepayment.repaymentRate}</Table.Td>
        <Table.Td>{studentLoanRepayment.repayment}</Table.Td>
        <Table.Td>{studentLoanRepayment.planName2}</Table.Td>
        <Table.Td>{studentLoanRepayment.repaymentThreshold2}</Table.Td>
        <Table.Td>{studentLoanRepayment.repaymentRate2}</Table.Td>
        <Table.Td>{studentLoanRepayment.repayment2}</Table.Td>
      </Table.Tr>
    ) : (
      <Table.Tr
        key={`student-loan-repayment-${studentLoanRepayment.planName}-${idx}`}
      >
        <Table.Td>{studentLoanRepayment.planName}</Table.Td>
        <Table.Td>{studentLoanRepayment.repaymentThreshold}</Table.Td>
        <Table.Td>{studentLoanRepayment.repaymentRate}</Table.Td>
        <Table.Td>{studentLoanRepayment.repaymentMonthly}</Table.Td>
        <Table.Td>{studentLoanRepayment.repayment}</Table.Td>
      </Table.Tr>
    )
  );

  return (
    <Table.ScrollContainer mt="md" minWidth={190 + 290 * data.length}>
      <Table
        striped
        withTableBorder
        withColumnBorders
        highlightOnHover
        verticalSpacing="xs"
      >
        <Table.Thead>
          {data.length > 1 && (
            <Table.Tr>
              <Table.Th colSpan={4} w="50%">
                <Text fz="sm">{getTitle(data[0].inputs)}</Text>
              </Table.Th>
              <Table.Th colSpan={4} w="50%">
                <Text fz="sm">{getTitle(data[1].inputs)}</Text>
              </Table.Th>
            </Table.Tr>
          )}
          {data.length > 1 ? (
            <Table.Tr>
              <Table.Th>Plan</Table.Th>
              <Table.Th>Threshold</Table.Th>
              <Table.Th>Rate</Table.Th>
              <Table.Th>Year</Table.Th>
              <Table.Th>Plan</Table.Th>
              <Table.Th>Threshold</Table.Th>
              <Table.Th>Rate</Table.Th>
              <Table.Th>Year</Table.Th>
            </Table.Tr>
          ) : (
            <Table.Tr>
              <Table.Th>Plan</Table.Th>
              <Table.Th>Threshold</Table.Th>
              <Table.Th>Rate</Table.Th>
              <Table.Th>Month</Table.Th>
              <Table.Th>Year</Table.Th>
            </Table.Tr>
          )}
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
        <Table.Tfoot>
          {data.length > 1 ? (
            <Table.Tr>
              <Table.Th>Total</Table.Th>
              <Table.Th></Table.Th>
              <Table.Th>
                {data[0].result.grossIncome > 0 &&
                  percFormatter(
                    data[0].result.studentLoanDeductions.reduce(
                      (acc, cur) => acc + cur.annualDeduction,
                      0
                    ) / data[0].result.grossIncome
                  )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(
                  data[0].result.studentLoanDeductions.reduce(
                    (acc, cur) => acc + cur.annualDeduction,
                    0
                  ) / 100.0
                )}
              </Table.Th>
              <Table.Th>Total</Table.Th>
              <Table.Th></Table.Th>
              <Table.Th>
                {data[1].result.grossIncome > 0 &&
                  percFormatter(
                    data[1].result.studentLoanDeductions.reduce(
                      (acc, cur) => acc + cur.annualDeduction,
                      0
                    ) / data[1].result.grossIncome
                  )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(
                  data[1].result.studentLoanDeductions.reduce(
                    (acc, cur) => acc + cur.annualDeduction,
                    0
                  ) / 100.0
                )}
              </Table.Th>
            </Table.Tr>
          ) : (
            <Table.Tr>
              <Table.Th>Total</Table.Th>
              <Table.Th></Table.Th>
              <Table.Th>
                {data[0].result.grossIncome > 0 &&
                  percFormatter(
                    data[0].result.studentLoanDeductions.reduce(
                      (acc, cur) => acc + cur.annualDeduction,
                      0
                    ) / data[0].result.grossIncome
                  )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(
                  data[0].result.studentLoanDeductions.reduce(
                    (acc, cur) => acc + cur.monthlyDeduction,
                    0
                  ) / 100.0
                )}
              </Table.Th>
              <Table.Th>
                {gbpFormatter(
                  data[0].result.studentLoanDeductions.reduce(
                    (acc, cur) => acc + cur.annualDeduction,
                    0
                  ) / 100.0
                )}
              </Table.Th>
            </Table.Tr>
          )}
        </Table.Tfoot>
      </Table>
    </Table.ScrollContainer>
  );
};
