import { Group, Paper, SimpleGrid, Text } from "@mantine/core";
import {
  IconArrowDownRight,
  IconArrowUpRight,
  IconCoinPound,
  IconEqual,
} from "@tabler/icons-react";
import { IncomeTaxBreakdown } from "backend/src/types";
import classes from "./StatsGrid.module.css";
import { percFormatter } from "../../utils";

const icons = {
  coin: IconCoinPound,
};

type StatsGridProps = {
  data: IncomeTaxBreakdown[];
};

export const StatsGrid: React.FC<StatsGridProps> = ({ data }) => {
  if (data.length < 2) {
    return null;
  }
  const salary1 = data[0];
  const salary2 = data[data.length - 1];

  const grossIncomeDiff =
    salary2.result.grossIncome - salary1.result.grossIncome;
  const grossIncomeDiffMonthly =
    salary2.result.grossIncomeMonthly - salary1.result.grossIncomeMonthly;
  const grossIncomeDiffPerc = grossIncomeDiff / salary1.result.grossIncome;

  const taxDiff = salary2.result.tax - salary1.result.tax;
  const taxDiffMonthly = salary2.result.taxMonthly - salary1.result.taxMonthly;
  const taxDiffPerc = taxDiff / salary1.result.tax;

  const netDiff = salary2.result.net - salary1.result.net;
  const netDiffMonthly = salary2.result.netMonthly - salary1.result.netMonthly;
  const netDiffPerc = netDiff / salary1.result.net;

  const deductionsDiff = salary2.result.deductions - salary1.result.deductions;
  const deductionsDiffMonthly =
    salary2.result.deductionsMonthly - salary1.result.deductionsMonthly;
  const deductionsDiffPerc = deductionsDiff / salary1.result.deductions || 0;

  const takeHomeDiff = salary2.result.takeHome - salary1.result.takeHome;
  const takeHomeDiffMonthly =
    salary2.result.takeHomeMonthly - salary1.result.takeHomeMonthly;
  const takeHomeDiffPerc = takeHomeDiff / salary1.result.takeHome;

  const pensionDiff = salary2.result.pensionPot - salary1.result.pensionPot;
  const pensionDiffMonthly =
    salary2.result.pensionPotMonthly - salary1.result.pensionPotMonthly;
  const pensionDiffPerc = pensionDiff / salary1.result.pensionPot || 0;

  const stats = [
    {
      title: "Gross Income",
      icon: "coin",
      value: grossIncomeDiff / 100,
      valueMonthly: grossIncomeDiffMonthly / 100,
      diff: grossIncomeDiffPerc,
    },
    {
      title: "Total Tax",
      icon: "coin",
      value: taxDiff / 100,
      valueMonthly: taxDiffMonthly / 100,
      diff: taxDiffPerc,
    },
    {
      title: "Net Income",
      icon: "coin",
      value: netDiff / 100,
      valueMonthly: netDiffMonthly / 100,
      diff: netDiffPerc,
    },
    {
      title: "Deductions",
      icon: "coin",
      value: deductionsDiff / 100,
      valueMonthly: deductionsDiffMonthly / 100,
      diff: deductionsDiffPerc,
    },
    {
      title: "Take Home",
      icon: "coin",
      value: takeHomeDiff / 100,
      valueMonthly: takeHomeDiffMonthly / 100,
      diff: takeHomeDiffPerc,
    },
    {
      title: "Pension Pot",
      icon: "coin",
      value: pensionDiff / 100,
      valueMonthly: pensionDiffMonthly / 100,
      diff: pensionDiffPerc,
    },
  ];

  const gridItems = stats.map((stat) => {
    const Icon = icons.coin;

    const getDiffIcon = () => {
      if (stat.diff === 0) return IconEqual;
      return stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;
    };

    const DiffIcon = getDiffIcon();

    const getDiffColor = () => {
      if (stat.diff === 0) return "grey";
      switch (stat.title) {
        case "Gross Income":
        case "Net Income":
        case "Take Home":
        case "Pension Pot":
          return stat.diff > 0 ? "teal" : "red";
        default:
          return stat.diff > 0 ? "red" : "teal";
      }
    };

    return (
      <Paper withBorder p="md" radius="md" key={stat.title}>
        <Group justify="space-between">
          <Text size="xs" c="dimmed" className={classes.title}>
            {stat.title}
          </Text>
          <Icon className={classes.icon} size="1.4rem" stroke={1.5} />
        </Group>

        <Group align="flex-end" gap="xs" mt={25}>
          <Text className={classes.value}>
            {Intl.NumberFormat("en-GB", {
              style: "currency",
              currency: "GBP",
              signDisplay: "exceptZero",
            }).format(stat.value)}
          </Text>
          <Text c={getDiffColor()} fz="sm" fw={500} className={classes.diff}>
            <span>{percFormatter(stat.diff)}</span>
            <DiffIcon size="1rem" stroke={1.5} />
          </Text>
        </Group>
        <Text className={classes.value}>
          {`${Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
            signDisplay: "exceptZero",
          }).format(stat.valueMonthly)} / mo`}
        </Text>

        <Text fz="xs" c="dimmed" mt={7}>
          Compared to Salary 1
        </Text>
      </Paper>
    );
  });
  return (
    <div className={classes.root}>
      <SimpleGrid cols={{ base: 1, xs: 2, lg: 3 }}>{gridItems}</SimpleGrid>
    </div>
  );
};
