import { Button, NumberInput, NumberInputProps, rem } from "@mantine/core";
import { IconCoinPound, IconSettings } from "@tabler/icons-react";
import { useIncomeFormContext } from "../../context/form-context";

type InputWithButtonProps = NumberInputProps & {
  pathPrefix: string;
  onRightSectionClick?: () => void;
};

export const InputWithButton: React.FC<InputWithButtonProps> = ({
  pathPrefix,
  onRightSectionClick,
  ...props
}) => {
  const form = useIncomeFormContext();
  return (
    <NumberInput
      fixedDecimalScale
      decimalScale={2}
      allowNegative={false}
      thousandSeparator=","
      radius="xl"
      size="lg"
      placeholder="Enter salary"
      rightSectionWidth={42}
      leftSection={
        <IconCoinPound
          style={{ width: rem(18), height: rem(18) }}
          stroke={1.5}
        />
      }
      rightSection={
        <Button
          pos="absolute"
          right={7}
          radius="xl"
          rightSection={
            <IconSettings
              style={{ width: rem(18), height: rem(18) }}
              stroke={1.5}
            />
          }
          style={{ right: "calc(3rem)", left: "unset" }}
          onClick={onRightSectionClick}
        >
          Configure
        </Button>
      }
      {...props}
      {...form.getInputProps(`${pathPrefix}.grossIncome`)}
    />
  );
};
