import { Group, Progress, Table, Text, Tooltip } from "@mantine/core";
import { IconInfoCircleFilled } from "@tabler/icons-react";
import { IncomeTaxBreakdown } from "backend/src/types";
import React from "react";
import { gbpFormatter, getTitle, percFormatter } from "../../utils";
import { SalaryBreakdownPieChart } from "../SalaryBreakdownPieChart/SalaryBreakdownPieChart";
import classes from "./TableSalaries.module.css";

type TableSalariesProps = {
  data: IncomeTaxBreakdown[];
};

export const TableSalaries: React.FC<TableSalariesProps> = ({ data }) => {
  const annualDataKeys = [
    "title",
    "grossIncome",
    "incomeTax",
    "nationalInsuranceTax",
    "net",
    "studentLoanDeductions",
    "pensionDeductions",
    "takeHome",
    "incomeDistribution",
  ];

  const rowHeaders = [
    "",
    "Gross Income",
    "Income Tax",
    "NI Tax",
    "Net Income",
    "Student Loan",
    "Pension",
    "Take Home",
    "",
  ];

  const renderColumn = (salary: IncomeTaxBreakdown, key: string) => {
    switch (key) {
      case "title":
        return <Table.Th w={"12%"}>Year</Table.Th>;
      case "titleMonthly":
        return <Table.Th w={"12%"}>Month</Table.Th>;
      case "titleWeekly":
        return <Table.Th w={"12%"}>Week</Table.Th>;
      case "titleDaily":
        return (
          <Table.Th w={"12%"}>
            Day{" "}
            <Tooltip label="220 working days/year">
              <IconInfoCircleFilled size={14} />
            </Tooltip>
          </Table.Th>
        );
      case "titleHourly":
        return (
          <Table.Th w={"12%"}>
            Hour{" "}
            <Tooltip label="7.5 working hours/day">
              <IconInfoCircleFilled size={14} />
            </Tooltip>
          </Table.Th>
        );
      case "incomeDistribution":
        return (
          <Table.Td colSpan={data.length > 1 ? 3 : 5}>
            <Group justify="space-between">
              <Text fz="xs" c="teal" fw={700}>
                {percFormatter(
                  salary.result.takeHome / salary.result.grossIncome
                )}{" "}
                take home
              </Text>
              <Text fz="xs" c="yellow" fw={700}>
                {percFormatter(
                  salary.result.deductions / salary.result.grossIncome
                )}{" "}
                deductions
              </Text>
              <Text fz="xs" c="red" fw={700}>
                {percFormatter(salary.result.tax / salary.result.grossIncome)}{" "}
                tax
              </Text>
            </Group>
            <Progress.Root>
              <Progress.Section
                className={classes.progressSection}
                value={
                  (100 * salary.result.takeHome) / salary.result.grossIncome
                }
                color="teal"
              />
              <Progress.Section
                className={classes.progressSection}
                value={
                  (100 * salary.result.deductions) / salary.result.grossIncome
                }
                color="yellow"
              />
              <Progress.Section
                className={classes.progressSection}
                value={(100 * salary.result.tax) / salary.result.grossIncome}
                color="red"
              />
            </Progress.Root>
          </Table.Td>
        );
      case "studentLoanDeductions":
        return (
          <Table.Td align="right">
            {gbpFormatter(
              salary.result.studentLoanDeductions.reduce(
                (total, deduction) => total + deduction.annualDeduction,
                0
              ) / 100.0
            )}
          </Table.Td>
        );
      case "studentLoanDeductionsMonthly":
        return (
          <Table.Td align="right">
            {gbpFormatter(
              salary.result.studentLoanDeductions.reduce(
                (total, deduction) => total + deduction.monthlyDeduction,
                0
              ) / 100.0
            )}
          </Table.Td>
        );
      case "studentLoanDeductionsWeekly":
        return (
          <Table.Td align="right">
            {gbpFormatter(
              salary.result.studentLoanDeductions.reduce(
                (total, deduction) => total + deduction.weeklyDeduction,
                0
              ) / 100.0
            )}
          </Table.Td>
        );
      case "studentLoanDeductionsDaily":
        return (
          <Table.Td align="right">
            {gbpFormatter(
              salary.result.studentLoanDeductions.reduce(
                (total, deduction) => total + deduction.dailyDeduction,
                0
              ) / 100.0
            )}
          </Table.Td>
        );
      case "studentLoanDeductionsHourly":
        return (
          <Table.Td align="right">
            {gbpFormatter(
              salary.result.studentLoanDeductions.reduce(
                (total, deduction) => total + deduction.hourlyDeduction,
                0
              ) / 100.0
            )}
          </Table.Td>
        );
      case "grossIncome":
      case "taxableIncome":
      case "incomeTax":
      case "nationalInsuranceTax":
      case "tax":
      case "net":
      case "pensionDeductions":
      case "deductions":
      case "takeHome":
      case "grossIncomeMonthly":
      case "taxableIncomeMonthly":
      case "incomeTaxMonthly":
      case "nationalInsuranceTaxMonthly":
      case "taxMonthly":
      case "netMonthly":
      case "pensionDeductionsMonthly":
      case "deductionsMonthly":
      case "takeHomeMonthly":
      case "grossIncomeWeekly":
      case "taxableIncomeWeekly":
      case "incomeTaxWeekly":
      case "nationalInsuranceTaxWeekly":
      case "taxWeekly":
      case "netWeekly":
      case "pensionDeductionsWeekly":
      case "deductionsWeekly":
      case "takeHomeWeekly":
      case "grossIncomeDaily":
      case "taxableIncomeDaily":
      case "incomeTaxDaily":
      case "nationalInsuranceTaxDaily":
      case "taxDaily":
      case "netDaily":
      case "pensionDeductionsDaily":
      case "deductionsDaily":
      case "takeHomeDaily":
      case "grossIncomeHourly":
      case "taxableIncomeHourly":
      case "incomeTaxHourly":
      case "nationalInsuranceTaxHourly":
      case "taxHourly":
      case "netHourly":
      case "pensionDeductionsHourly":
      case "deductionsHourly":
      case "takeHomeHourly":
        return (
          <Table.Td align="right">
            {gbpFormatter(salary.result[key] / 100.0)}
          </Table.Td>
        );
      default:
        return null;
    }
  };

  const renderRows = React.useMemo(
    () =>
      annualDataKeys.map((key, idx) => (
        <Table.Tr key={key}>
          <Table.Th className={classes.header}>{rowHeaders[idx]}</Table.Th>
          {data.length > 0 && renderColumn(data[0], key)}
          {data.length > 0 && renderColumn(data[0], `${key}Monthly`)}
          {data.length > 0 && renderColumn(data[0], `${key}Weekly`)}
          {data.length === 1 && renderColumn(data[0], `${key}Daily`)}
          {data.length === 1 && renderColumn(data[0], `${key}Hourly`)}
          {data.length > 1 && renderColumn(data[1], key)}
          {data.length > 1 && renderColumn(data[1], `${key}Monthly`)}
          {data.length > 1 && renderColumn(data[1], `${key}Weekly`)}
        </Table.Tr>
      )),
    [data, annualDataKeys, rowHeaders]
  );

  return (
    <Table.ScrollContainer mt="md" minWidth={190 + 290 * data.length}>
      <Table
        striped
        withTableBorder
        withColumnBorders
        highlightOnHover
        verticalSpacing="xs"
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th className={classes.header} w="20%" />
            {data.map((salary, idx) => (
              <Table.Th
                key={`th-salary-${idx}`}
                colSpan={data.length === 2 ? 3 : 5}
                w={data.length === 2 ? "40%" : "80%"}
              >
                <Group align="flex-end" gap="xs" mt={25}>
                  Salary {idx + 1}
                </Group>
                <Text fz="xs">{getTitle(salary.inputs)}</Text>{" "}
                <SalaryBreakdownPieChart incomeTaxBreakdown={data[idx]} />
              </Table.Th>
            ))}
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{renderRows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};
