import { PieChart } from "@mantine/charts";
import { IncomeTaxBreakdown } from "backend/src/types";
import React from "react";
import classes from "./SalaryBreakdownPieChart.module.css";
import { gbpFormatter } from "../../utils";

type SalaryBreakdownPieChartProps = {
  incomeTaxBreakdown: IncomeTaxBreakdown;
};

export const SalaryBreakdownPieChart: React.FC<
  SalaryBreakdownPieChartProps
> = ({ incomeTaxBreakdown }) => {
  const { incomeTax, nationalInsuranceTax, pensionDeductions, takeHome } =
    incomeTaxBreakdown.result;
  const studentLoanDeductions =
    incomeTaxBreakdown.result.studentLoanDeductions.reduce(
      (acc, cur) => acc + cur.annualDeduction,
      0
    );
  const data = [];
  if (incomeTax) {
    data.push({
      name: "Income Tax",
      value: incomeTax,
      color: "red",
    });
  }
  if (nationalInsuranceTax) {
    data.push({
      name: "NI Tax",
      value: nationalInsuranceTax,
      color: "blue",
    });
  }
  if (pensionDeductions) {
    data.push({
      name: "Pension",
      value: pensionDeductions,
      color: "orange",
    });
  }
  if (studentLoanDeductions) {
    data.push({
      name: "Student Loans",
      value: studentLoanDeductions,
      color: "pink",
    });
  }
  if (takeHome) {
    data.push({
      name: "Take Home",
      value: takeHome,
      color: "lime",
    });
  }
  return (
    <>
      <PieChart
        withLabelsLine
        labelsPosition="outside"
        labelsType="percent"
        withLabels
        w={220}
        h={220}
        data={data}
        className={classes.root}
        mx="auto"
        tooltipDataSource="segment"
        withTooltip
        valueFormatter={(value) => `${gbpFormatter(value / 100.0)}`}
        tooltipProps={{
          wrapperStyle: {
            fontWeight: "normal",
            fontSize: "9pt",
            background: "white",
            boxShadow: "1px 2px 4px rgba(0,0,0,0.5)",
            padding: "6px",
            borderRadius: "6px",
          },
        }}
      />
    </>
  );
};
